import React, {Component} from 'react';
import {Button, Layout, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import MIDISounds from 'midi-sounds-react';
import {CaretRightOutlined} from '@ant-design/icons';
import {MidiPlayer} from "./MidiPlayer";
import {MusicXml} from "./MusicXml";
import {majorScale} from "./majorScale";

const {Content} = Layout;

export default class MajorScaleContainer extends Component {

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Skala durowa
                    </Typography.Title>

                    <p style={{marginTop: '16px'}}>
                        Skala durowa (z łac. durus – twardy), zwana też majorową (z łac. maior – większy, odnosi się do
                        tercji wielkiej zawartej między I a III stopniem skali) – siedmiostopniowa skala z
                        charakterystycznym półtonem między stopniami III i IV oraz VII i VIII (stanowiącym
                        powtórzenie I stopnia o oktawę wyżej). Uznaje się, że skala durowa i utwory na niej oparte mają
                        radosne brzmienie.
                    </p>

                    <div style={{display: 'none'}}>
                        <MIDISounds
                            ref={(ref) => (this.midiSounds = ref)}
                            appElementName="root" instruments={[3]}
                        />
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {majorScale.map((item, key) => {
                            return <div key={key} style={{width: '100%', textAlign: 'left'}}>
                                <Typography.Title level={4}>Gama {item.name}</Typography.Title>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildScaleMusicXML(item)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                />
                                {item.fifths !== 0 ? <OpenSheetMusicDisplay
                                    file={MusicXml.buildScaleMusicXML(item, true)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                /> : null}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <Button icon={<CaretRightOutlined/>}
                                            onClick={() => MidiPlayer.playScale(this.midiSounds, item)}>
                                        Zagraj
                                    </Button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
