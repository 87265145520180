import React, {Component} from "react";
import {Form, Input, Button} from 'antd';
import {Link} from 'react-router-dom'

class RecoverPasswordForm extends Component {

    formRef = React.createRef();

    handleSubmit = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                this.props.handleSubmit(values, () => {
                    this.formRef.current.resetFields()
                });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    render() {

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <React.Fragment>
                <h1 style={{
                    margin: '0 auto',
                    background: 'none',
                    padding: '2em 0 1em 0',
                    textAlign: 'center'
                }}>Przywracanie hasła</h1>

                <Form {...formItemLayout}
                      onFinish={this.handleSubmit}
                      ref={this.formRef}>

                    <p>Aby ustawić nowe hasło, podaj adres e-mail powiązany z Twoim kontem</p>

                    <Form.Item name="email"
                               label="E-mail"
                               rules={[
                                   {
                                       type: 'email',
                                       message: 'To nie jest poprawny adres e-mail',
                                   },
                                   {
                                       required: true,
                                       message: 'Proszę wpisać adres e-mail',
                                   },
                               ]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Wyślij
                        </Button>
                        <Link to="/login" style={{marginLeft: '1em'}}>
                            Zaloguj się
                        </Link>
                    </Form.Item>

                </Form>
            </React.Fragment>
        );
    }
};

export default RecoverPasswordForm;
