export const DataTypes = {
    COMPOSITIONS: 'compositions',
    COMPOSITION_FILES: 'composition_files',
    COMPOSITION_LINKS: 'composition_links',
    SOURCES: 'sources',
    CATEGORIES: 'categories',
    FEASTS: 'feasts',
    EVENTS: 'events',
    EVENT_PARTS: 'event_parts',
    PARTS: 'parts'
}

export const Lists = {
    FEAST_WEIGHTS: 10,
    FEAST_MOVABILITY: 11,
    FEAST_FUNCTIONS: 12,
    ENTITY_TYPES: 13,
    FEAST_PRIORITY: 14,
    INSTRUMENT_GROUPS: 15,
    GROUP_TYPES: 16,
    EVENT_TYPES: 17,
    COMPOSITION_SOURCE_TYPES: 18
}

export const ActionTypes = {
    START_LOADING: 'start_loading',
    GET_COLLECTION: 'get_collection',
    GET_ENTITY: 'get_entity',
    SAVE_ENTITY: 'save_entity',
    DELETE_ENTITY: 'delete_entity',
    START_LOADER: 'start_loader',
    LOADING_ERROR: 'loading_error',
    LOGIN: 'login',
    LOGIN_ERROR: 'login_error',
    LOGOUT: 'logout',
    REGISTER: 'register',
    REGISTER_ERROR: 'register_error',
    RECOVER_PASSWORD: 'recoverPassword',
    RECOVER_PASSWORD_ERROR: 'recoverPasswordError',
    SET_PASSWORD: 'setPassword',
    SET_PASSWORD_ERROR: 'setPasswordError',
    DOWNLOAD_FILE: 'downloadFile',
    DOWNLOAD_FILE_ERROR: 'downloadFileError',
    OPEN_FILE: 'openFile',
    OPEN_FILE_ERROR: 'openFileError',
    DELETE_FILE: 'deleteFile',
    DELETE_FILE_ERROR: 'deleteFileError',
    PROFILE: 'profile',
    PROFILE_ERROR: 'profileError',
    GET_LIST: 'get_list',
    SEARCH_COMPOSITIONS: 'search_compositions',
    PRINT_REPERTOIRE: 'print_repertoire',
    GET_EVENT: 'getEvent'
}
