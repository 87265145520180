import React, {Component} from "react";
import {Empty} from "antd";

export class CompositionDescription extends Component {
    render() {
        return this.props.description ?
            <div>
                {this.props.description}
            </div> :
            <Empty description={'Brak opisu utworu'}></Empty>
    }
}
