import React, {Component} from "react";
import LoginForm from "./LoginForm";

export class LoginContainer extends Component {

    handleSubmit = (credentials, onSuccess) => {
        this.props.login(credentials, this.props.history, onSuccess);
    }

    render() {
        return (<div style={{maxWidth: '300px'}}>
            <LoginForm
                handleSubmit={this.handleSubmit}
            />
        </div>);
    }
}
