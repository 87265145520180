import React, {Component} from "react";

export class CompositionInfo extends Component {
    render() {
        const {composition} = this.props;
        return <p style={{fontStyle: 'italic'}}>
            {composition.lyrics_author ? 'sł.: ' + composition.lyrics_author : ''}
            {composition.composer ? ' m.: ' + composition.composer : ''}
            {composition.arranger ? ' opr.: ' + composition.arranger : ''}
        </p>
    }
}
