import React, {Component} from 'react';
import {Modal, Form, Input, Select, Button, DatePicker} from 'antd';
import {
    CloseOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {amIAdmin} from "../../helpers/auth";

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 16,
    },
};

export class EventModal extends Component {
    formRef = React.createRef();

    handleOk = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                this.props.handleOk(this.props.data.id, values);
                //this.formRef.current.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    handleCancel = () => {
        this.props.handleCancel();
        //this.formRef.current.resetFields();
    }

    handleRepertoire = () => {
        this.props.handleRepertoire();
    }

    render() {
        const buttons = [
            <Button key="repertoire" onClick={this.handleRepertoire}>
                Repertuar
            </Button>,
            <Button icon={<CloseOutlined/>} key="cancel" onClick={this.handleCancel}>
                Zamknij
            </Button>
        ];
        if (amIAdmin()) {
            buttons.push(<Button icon={<SaveOutlined/>} key="search" type="primary" onClick={this.handleOk}>
                Zapisz
            </Button>);
        }

        //console.log(this.props.data)
        return <Modal title={this.props.title}
                      visible={this.props.visible}
                      width={700}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={buttons}>
            <Form {...layout}
                  ref={this.formRef}
                  fields={Object.keys(this.props.data).map(key => {
                      return {
                          name: key,
                          value: (key === 'start' || key === 'end') ? this.props.data[key] ? moment(this.props.data[key]) : null : this.props.data[key]
                      }
                  })
                  }
            >
                <Form.Item
                    name="type_id"
                    label="Rodzaj"
                    extra={"Rodzaj wydarzenia"}
                    rules={[
                        {
                            required: true,
                            message: 'Wskaż rodzaj wydarzenia'
                        }
                    ]}
                >
                    <Select allowClear={true}
                            showSearch={true}
                            optionFilterProp={'label'}
                    >
                        {
                            this.props.eventTypes ?
                                this.props.eventTypes.map((eventType) => <Select.Option key={eventType.id}
                                                                                        value={eventType.id}
                                                                                        label={eventType.name}>
                                    {eventType.name}
                                </Select.Option>) : null
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="start"
                    label="Początek"
                    extra={"Data i godzina rozpoczęcia wydarzenia"}
                    rules={[
                        {
                            required: true,
                            message: 'Podaj początek wydarzenia'
                        }
                    ]}
                >
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm"
                    />
                </Form.Item>

                <Form.Item
                    name="end"
                    label="Koniec"
                    extra={"Data i godzina zakończenia wydarzenia"}
                    rules={[
                        {
                            required: true,
                            message: 'Podaj koniec wydarzenia'
                        }
                    ]}
                >
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm"
                    />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Opis"
                >
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    }
}
