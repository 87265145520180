import React, {Component} from "react";
import LoginLayoutRoute from "./LoginLayout";
import DashboardLayoutRoute from "./DashboardLayout";
import {connect} from "react-redux";
import {Spin} from 'antd';
import HomeContainer from "./containers/home/HomeContainer";
import {SongbookConnector} from "./containers/songbook/SongbookConnector";
import {LoginConnector} from "./containers/login/LoginConnector";
import {RegisterConnector} from "./containers/login/RegisterConnector";
import {RecoverPasswordConnector} from "./containers/login/RecoverPasswordConnector";
import {SetPasswordConnector} from "./containers/login/SetPasswordConnector";
import {CalendarConnector} from "./containers/calendar/CalendarConnector";
import {RepertoireConnector} from "./containers/repertoire/RepertoireConnector";
import {EventConnector} from "./containers/event/EventConnector";
import EmptyLayoutRoute from "./EmptyLayout";
import {LyricsConnector} from "./containers/event/LyricsConnector";
import {Switch} from "react-router-dom";
import {Exercises} from "./containers/exercises/Exercises";

const routes = [
    {
        path: "/",
        exact: true,
        component: HomeContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/register",
        component: RegisterConnector,
        layout: LoginLayoutRoute
    },
    {
        path: "/login",
        component: LoginConnector,
        layout: LoginLayoutRoute
    },
    {
        path: "/recover-password",
        component: RecoverPasswordConnector,
        layout: LoginLayoutRoute
    },
    {
        path: "/set-password/:token",
        component: SetPasswordConnector,
        layout: LoginLayoutRoute
    },
    {
        path: "/songbook/:id?",
        component: SongbookConnector,
        layout: DashboardLayoutRoute
    },
    {
        path: "/calendar",
        component: CalendarConnector,
        layout: DashboardLayoutRoute
    },
    {
        path: "/repertoire/:id",
        component: RepertoireConnector,
        layout: DashboardLayoutRoute
    },
    {
        path: "/e/:id/:part?",
        component: EventConnector,
        layout: EmptyLayoutRoute
    },
    {
        path: "/l/:id",
        component: LyricsConnector,
        layout: EmptyLayoutRoute
    },
    {
        path: "/exercises",
        component: Exercises,
        layout: DashboardLayoutRoute
    }
];

export class AppRouter extends Component {
    state = {loading: false};

    render() {
        return (
            <div style={{height: '100%'}}>
                {
                    this.state.loading ?
                        <div>
                            <h1 style={{textAlign: 'center', padding: '2em', height: '100%'}}>Uruchamianie
                                aplikacji</h1>
                            <Spin spinning={this.state.loading}>
                                <div></div>
                            </Spin>
                        </div>
                        :
                        <Switch>
                            {routes.map(route => {
                                const {path, exact, layout, ...otherProps} = route;
                                let LayoutRoute = layout;
                                return (
                                    <LayoutRoute
                                        exact={!!exact}
                                        key={path}
                                        path={path}
                                        {...otherProps}
                                    >
                                    </LayoutRoute>
                                );
                            })}
                        </Switch>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    //dictionary: state.Dictionaries
});

export default connect(mapStateToProps, {
    //getAllDictionaries
})(AppRouter);
