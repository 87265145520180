import React from 'react';
import './App.css';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import AppRouter from "./App.Router";
import {DataStore} from "./data/dataStore";
import {Provider} from "react-redux";

const App = () => {
    return (
        <Provider store={DataStore}>
            <Router>
                <AppRouter/>
            </Router>
        </Provider>
    );
}

export default App;
