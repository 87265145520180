const isPromise = (payload) =>
    (typeof (payload) === 'object' || typeof (payload) === 'function')
    && typeof (payload.then) === 'function';

export const asyncActions = () => (next) => (action) => {
    if (isPromise(action.payload)) {
        action.payload
            .then(result => {
                //console.log('Success');
                next({...action, payload: result});
                if (action.onSuccess) {
                    action.onSuccess(result);
                }
            })
            .catch(error => {
                console.log('Error', error);
                if (action.onError) {
                    action.onError(error);
                }
            });
    } else {
        next(action);
    }
}
