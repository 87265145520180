import React, {Component} from 'react';
import moment from 'moment';
import 'moment/locale/pl';
import locale from 'antd/es/date-picker/locale/pl_PL';
import './Calendar.css';
import {DatePicker, Tooltip} from "antd";

export default class Calendar extends Component {
    state = {
        dateContext: moment(),
        today: moment(),
        showMonthPopup: false,
        showYearNav: false,
        showToday: true,
        showSelectedDay: false,
        selectedDay: null
    }

    constructor(props) {
        super(props);
        this.width = props.width || "100%";
        this.style = props.style || {};
        this.style.width = this.width;
    }

    componentDidMount(): void {
        this.props.onChange(this.startDate(), this.endDate());
    }

    weekdays = moment.weekdays();

    year = () => {
        return this.state.dateContext.format("Y");
    }

    month = () => {
        return this.state.dateContext.format("MMMM");
    }

    daysInMonth = () => {
        return this.state.dateContext.daysInMonth();
    }

    currentDate = () => {
        return this.state.dateContext.get("date");
    }

    currentDay = () => {
        return parseInt(this.state.dateContext.format("D"));
    }

    firstDayOfMonth = () => {
        return moment(this.state.dateContext).startOf('month').format('d'); //Day of week 0..6
    }

    lastDayOfMonth = () => {
        return moment(this.state.dateContext).endOf('month').format('d'); //Day of week 0..6
    }

    startDate = () => {
        return moment(this.state.dateContext).startOf('month').format('YYYY-MM-DD');
    }

    endDate = () => {
        return moment(this.state.dateContext).endOf('month').format('YYYY-MM-DD');
    }

    nextMonth = () => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).add(1, "month");
        this.setState({
            dateContext: dateContext
        });
        this.props.onNextMonth && this.props.onNextMonth();
    }

    prevMonth = () => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).subtract(1, "month");
        this.setState({
            dateContext: dateContext
        });
        this.props.onPrevMonth && this.props.onPrevMonth();
    }

    onDayClick = (e, day) => {
        this.setState({
            selectedDay: day
        }, () => {
            this.props.onDayClick && this.props.onDayClick(e, day);
        });
    }

    onChange = (date, dateString) => {
        //console.log(date, dateString);
        if (date) {
            this.setState({
                dateContext: date
            }, () => {
                this.props.onChange(this.startDate(), this.endDate());
            });
        }
    }

    render() {
        let weekdays = this.weekdays.map((day, key) => {
            let className = key === 0 ? "week-day holiday" : "week-day";
            return <td key={day} className={className} style={{width: '14%'}}>{day}</td>
        });

        let feastsByDay = {};
        let holidays = [];
        if (this.props.feasts) {
            for (let f = 0; f < this.props.feasts.length; f++) {
                if (this.props.feasts[f].year_date) {
                    let d = moment(this.props.feasts[f].year_date).format('D');
                    if (!feastsByDay[d]) {
                        feastsByDay[d] = [];
                    }
                    let className = (this.props.feasts[f].weight_type === '7' ? "obchod secular" : "obchod");
                    if (this.props.feasts[f].weight_type === '1') {
                        className += " solemnity";
                    } else if (this.props.feasts[f].weight_type === '2') {
                        className += " feast";
                    }
                    if (this.props.feasts[f].holiday === '1') {
                        holidays[d] = true;
                    }
                    feastsByDay[d].push(<Tooltip
                        title={this.props.feasts[f].weight_type !== '6' && this.props.feasts[f].weight_type_name}
                        key={this.props.feasts[f].id}
                    >
                        <div
                            className={className}
                        >{this.props.feasts[f].name_pl}</div>
                    </Tooltip>);
                }
            }
        }

        let eventsByDay = {};
        if (this.props.events) {
            for (let e = 0; e < this.props.events.length; e++) {
                if (this.props.events[e].start) {
                    let m = moment(this.props.events[e].start);
                    let d = m.format('D');
                    let t = m.format('H:mm');
                    if (!eventsByDay[d]) {
                        eventsByDay[d] = [];
                    }
                    eventsByDay[d].push(<Tooltip
                        title={this.props.events[e].description}
                        key={this.props.events[e].id}
                    >
                        <div
                            className="event event-blue"
                            onClick={() => this.props.onEventClick(this.props.events[e])}
                        >
                            {t} {this.props.events[e].type_name}
                        </div>
                    </Tooltip>);
                }
            }
        }

        //console.log(feastsByDay);

        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<td key={i * 80} className="empty" style={{width: '14%'}}></td>);
        }

        let blanks2 = [];
        const from = parseInt(this.lastDayOfMonth()) + 1;
        for (let i = from; i < 7; i++) {
            blanks2.push(<td key={(31 + i) * 80} className="empty" style={{width: '14%'}}></td>);
        }

        let daysInMonth = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let className = (d === this.currentDay() && this.state.showToday ? "day current-day" : "day");
            if (holidays[d]) {
                className += " holiday";
            }
            let selectedClass = (d === this.state.selectedDay && this.state.showSelectedDay ? " selected-day " : "");
            daysInMonth.push(<td key={d} className={className + selectedClass}>
                <span className="day-number" onClick={(e) => {
                    const {dateContext} = this.state;
                    let dt = moment(dateContext).set("date", d);
                    this.onDayClick(e, dt);
                }}>{d}</span>
                {feastsByDay[d]}
                {eventsByDay[d]}
            </td>);
        }

        let totalSlots = [...blanks, ...daysInMonth, blanks2];
        let rows = [];
        let cells = [];

        totalSlots.forEach((cell, i) => {
            if ((i % 7) !== 0) {
                cells.push(cell);
            } else {
                let insertRow = cells.slice();
                rows.push(insertRow);
                cells = [];
                cells.push(cell);
            }
            if (i === totalSlots.length - 1) {
                let insertRow = cells.slice();
                rows.push(insertRow);
            }
        });

        let trElems = rows.map((cells, i) => {
            return (
                <tr key={i * 100}>
                    {cells}
                </tr>
            );
        });

        return (
            <div id={this.props.id} className="calendar-container" style={this.style}>
                <table className="calendar">
                    <thead>
                    <tr className="calendar-header">
                        <td colSpan={7}>
                            <DatePicker
                                onChange={this.onChange}
                                picker="month"
                                locale={locale}
                                value={this.state.dateContext}
                            />
                        </td>
                    </tr>
                    <tr className="week-days">{weekdays}</tr>
                    </thead>
                    <tbody>
                    {trElems}
                    </tbody>
                </table>
            </div>
        )
    }
}
