import React from "react";
import {
    //FileOutlined,
    FileUnknownOutlined,
    FilePdfOutlined,
    FileMarkdownOutlined,
    FileImageOutlined,
    CodeOutlined
} from '@ant-design/icons';
import {Mp3Icon} from "../icons/icons";

export const FILE_TYPE_UNKNOWN = '0';
export const FILE_TYPE_PDF = '1';
export const FILE_TYPE_MSCZ = '2';
export const FILE_TYPE_MP3 = '3';
export const FILE_TYPE_SVG = '4';
export const FILE_TYPE_PNG = '5';
export const FILE_TYPE_MUSICXML = '6';

export const fileTypes = {
    [FILE_TYPE_UNKNOWN]: {type: 'nieznany', mimeType: '', icon: <FileUnknownOutlined/>},
    [FILE_TYPE_PDF]: {type: 'pdf', mimeType: 'application/pdf', icon: <FilePdfOutlined style={{color: 'red'}}/>},
    [FILE_TYPE_MSCZ]: {
        type: 'mscz',
        mimeType: 'application/x-musescore',
        icon: <FileMarkdownOutlined style={{color: '#777777'}}/>
    },
    [FILE_TYPE_MP3]: {type: 'mp3', mimeType: 'audio/mpeg', icon: <Mp3Icon/>},
    [FILE_TYPE_SVG]: {type: 'svg', mimeType: 'image/svg+xml', icon: <FileImageOutlined/>},
    [FILE_TYPE_PNG]: {type: 'png', mimeType: 'image/png', icon: <FileImageOutlined/>},
    [FILE_TYPE_MUSICXML]: {type: 'musicxml', mimeType: 'application/octet-stream', icon: <CodeOutlined/>}
}

export const getFileTypeIdByMimeType = (mimeType) => {
    let result = '0';
    Object.keys(fileTypes).forEach(key => {
        if (fileTypes[key].mimeType === mimeType) {
            result = key;
        }
    });
    return result;
}

export const parts = [
    {id: 10, name: 'Chór', name_en: 'Choir'},
    {id: 35, name: 'Partytura', name_en: 'Score'},
    {id: 1, name: 'Akordeon', name_en: 'Accordion'},
    {id: 6, name: 'Altówka', name_en: 'Viola'},
    {id: 13, name: 'Flet', name_en: 'Flute'},
    {id: 20, name: 'Gitara', name_en: 'Classical_Guitar'},
    {id: 21, name: 'Gitara basowa', name_en: 'Bass_Guitar'},
    {id: 26, name: 'Klarnet', name_en: 'Bb_Clarinet'},
    {id: 27, name: 'Klarnet (Obój)', name_en: 'Bb_Clarinet_(Oboe)'},
    {id: 30, name: 'Kontrabas', name_en: 'Contrabass'},
    {id: 33, name: 'Obój', name_en: 'Oboe'},
    {id: 34, name: 'Organy', name_en: 'Organ'},
    {id: 36, name: 'Perkusja', name_en: 'Drumset'},
    {id: 37, name: 'Pianino', name_en: 'Piano'},
    {id: 39, name: 'Puzon', name_en: 'Trombone'},
    {id: 44, name: 'Skrzypce 1', name_en: 'Violin_I'},
    {id: 45, name: 'Skrzypce 2', name_en: 'Violin_II'},
    {id: 46, name: 'Skrzypce 3', name_en: 'Violin_III'},
    {id: 55, name: 'Trąbka', name_en: 'Bb_Trumpet'},
    {id: 60, name: 'Wiolonczela', name_en: 'Violoncello'},
    {id: 61, name: 'Sekcja rytmiczna', name_en: 'Rhythm_Section'}
]

export const getPartByName = (name) => {
    for (let i = 0; i < parts.length; i++) {
        if (name.includes(parts[i].name_en + '.') || name.includes(parts[i].name_en.toLowerCase() + '.')) {
            return parts[i];
        }
    }
    return {id: 35, name: 'Partytura', name_en: 'Score'};
}
