import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {Layout} from "antd";
import Navbar from "./Navbar";

const {Content, Footer} = Layout;

export const DashboardLayout = ({children, ...rest}) => {
    return (
        <Layout>
            <Navbar/>
            <Content style={{marginTop: '24px'}}>
                {children}
            </Content>
            <Footer style={{textAlign: 'center'}}>Pedro Software & Art ©2019</Footer>
        </Layout>
    )
}

const DashboardLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => {
            return localStorage.getItem('user')
                ? <DashboardLayout>
                    <Component {...matchProps} />
                </DashboardLayout>
                : <Redirect to={{pathname: '/login', state: {from: matchProps.location}}}/>
        }}/>
    )
};

/*const DashboardLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <DashboardLayout>
                <Component {...matchProps} />
            </DashboardLayout>
        )}/>
    )
};*/

export default DashboardLayoutRoute;
