import React, {Component} from "react";
import Title from "antd/lib/typography/Title";
import {Tabs, Empty, Row, Col} from "antd";
import {CompositionLyrics} from "./CompositionLyrics";
import {CompositionInfo} from "./CompositionInfo";
import {CompositionDescription} from "./CompositionDescription";
import {CompositionTags} from "./CompositionTags";
import {CompositionYoutube} from "./CompositionYoutube";
import {CompositionFiles} from "./CompositionFiles";
import {CompositionScore} from "./CompositionScore";

export class CompositionView extends Component {
    render() {
        const {composition} = this.props;
        if (composition.id) {
            return <div>
                <Row>
                    <Col xs={24} sm={24} md={16}>
                        <Title level={3}>
                            {composition.id}. {composition.title}
                        </Title>
                    </Col>
                    <Col xs={24} sm={24} md={8} style={{textAlign: 'right'}}>
                        <CompositionTags composition={composition}/>
                    </Col>
                </Row>
                {
                    composition.subtitle ?
                        <Title level={5} style={{marginTop: '0'}}>
                            {composition.subtitle}
                        </Title> :
                        null
                }
                <CompositionInfo composition={composition}/>
                <Tabs defaultActiveKey="lyrics" tabPosition={"top"}>
                    <Tabs.TabPane tab={"Tekst"} key="lyrics">
                        <CompositionLyrics
                            lyrics={composition.lyrics}/>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={"Opis"} key="description">
                        <CompositionDescription
                            description={composition.description}/>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={"Youtube"} key="youtube">
                        <CompositionYoutube
                            composition={this.props.composition}
                            links={composition.links}
                            addLink={this.props.addLink}
                            deleteLink={this.props.deleteLink}
                        />
                    </Tabs.TabPane>

                    {composition.musicxml ?
                        <Tabs.TabPane tab={"MusicXML"} key="musicxml">
                            <CompositionScore
                                file={composition.musicxml}/>
                        </Tabs.TabPane> : null
                    }

                    <Tabs.TabPane tab={"Pliki"} key="files">
                        <CompositionFiles
                            composition={this.props.composition}
                            downloadFile={this.props.downloadFile}
                            openFile={this.props.openFile}
                            deleteFile={this.props.deleteFile}
                            addFile={this.props.addFile}
                            deleteLink={this.props.deleteLink}
                            parts={this.props.parts}
                            files={composition.files}
                            file={this.props.file}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>;
        } else {
            return <Empty description={'Brak danych'}></Empty>;
        }
    }
}
