import React, {Component} from 'react';
import {Modal, Form, Input, Button} from 'antd';
import {
    CloseOutlined,
    SaveOutlined
} from '@ant-design/icons';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

export class CompositionYoutubeModal extends Component {

    formRef = React.createRef();

    handleOk = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                //console.log(values);
                this.formRef.current.resetFields();
                this.props.handleOk(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    handleCancel = () => {
        this.formRef.current.resetFields();
        this.props.handleCancel();
    }

    render() {
        //console.log(this.props.data)
        return <Modal title={this.props.title}
                      visible={this.props.visible}
                      width={700}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                          <Button icon={<CloseOutlined/>} key="cancel" onClick={this.handleCancel}>
                              Anuluj
                          </Button>,
                          <Button
                              icon={<SaveOutlined/>}
                              key="save"
                              type="primary"
                              onClick={this.handleOk}>
                              Zapisz
                          </Button>
                      ]}>
            <Form {...layout}
                  ref={this.formRef}
                  fields={Object.keys(this.props.data).map(key => ({
                      name: key,
                      value: this.props.data[key]
                  }))
                  }
            >
                <Form.Item
                    name="link"
                    label="Link do nagrania"
                    rules={[
                        {
                            required: true,
                            message: 'Podaj link do nagrania'
                        }
                    ]}
                >
                    <Input placeholder={'https://www.youtube.com/watch?v=abcdefg'}/>
                </Form.Item>
                <Form.Item
                    name="link_title"
                    label="Tytuł"
                    rules={[
                        {
                            required: true,
                            message: 'Podaj tytuł nagrania'
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Opis"
                >
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    }
}
