export const intervals = [
    //od c
    {
        from: ['C', 4, 0],
        to: ['C', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['C', 4, 0],
        to: ['D', 4, -1],
        name: 'sekunda mała'
    },
    {
        from: ['C', 4, 0],
        to: ['D', 4, 0],
        name: 'sekunda wielka'
    },
    {
        from: ['C', 4, 0],
        to: ['E', 4, -1],
        name: 'tercja mała'
    },
    {
        from: ['C', 4, 0],
        to: ['E', 4, 0],
        name: 'tercja wielka'
    },
    {
        from: ['C', 4, 0],
        to: ['F', 4, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['C', 4, 0],
        to: ['F', 4, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['C', 4, 0],
        to: ['G', 4, -1],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['C', 4, 0],
        to: ['G', 4, 0],
        name: 'kwinta czysta'
    },
    {
        from: ['C', 4, 0],
        to: ['A', 4, -1],
        name: 'seksta mała'
    },
    {
        from: ['C', 4, 0],
        to: ['A', 4, 0],
        name: 'seksta wielka'
    },
    {
        from: ['C', 4, 0],
        to: ['B', 4, -1],
        name: 'septyma mała'
    },
    {
        from: ['C', 4, 0],
        to: ['B', 4, 0],
        name: 'septyma wielka'
    },
    {
        from: ['C', 4, 0],
        to: ['C', 5, 0],
        name: 'oktawa czysta'
    },
    //od cis
    {
        from: ['C', 4, 1],
        to: ['C', 4, 1],
        name: 'pryma czysta'
    },
    {
        from: ['C', 4, 1],
        to: ['D', 4, 0],
        name: 'sekunda mała'
    },
    {
        from: ['C', 4, 1],
        to: ['D', 4, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['C', 4, 1],
        to: ['E', 4, 0],
        name: 'tercja mała'
    },
    {
        from: ['C', 4, 1],
        to: ['E', 4, 1],
        name: 'tercja wielka'
    },
    {
        from: ['C', 4, 1],
        to: ['F', 4, 1],
        name: 'kwarta czysta'
    },
    {
        from: ['C', 4, 1],
        to: ['F', 4, 2],
        name: 'kwarta zwiększona'
    },
    {
        from: ['C', 4, 1],
        to: ['G', 4, 0],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['C', 4, 1],
        to: ['G', 4, 1],
        name: 'kwinta czysta'
    },
    {
        from: ['C', 4, 1],
        to: ['A', 4, 0],
        name: 'seksta mała'
    },
    {
        from: ['C', 4, 1],
        to: ['A', 4, 1],
        name: 'seksta wielka'
    },
    {
        from: ['C', 4, 1],
        to: ['B', 4, 0],
        name: 'septyma mała'
    },
    {
        from: ['C', 4, 1],
        to: ['B', 4, 1],
        name: 'septyma wielka'
    },
    {
        from: ['C', 4, 1],
        to: ['C', 5, 1],
        name: 'oktawa czysta'
    },
    //od d
    {
        from: ['D', 4, 0],
        to: ['D', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['D', 4, 0],
        to: ['E', 4, -1],
        name: 'sekunda mała'
    },
    {
        from: ['D', 4, 0],
        to: ['E', 4, 0],
        name: 'sekunda wielka'
    },
    {
        from: ['D', 4, 0],
        to: ['F', 4, 0],
        name: 'tercja mała'
    },
    {
        from: ['D', 4, 0],
        to: ['F', 4, 1],
        name: 'tercja wielka'
    },
    {
        from: ['D', 4, 0],
        to: ['G', 4, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['D', 4, 0],
        to: ['G', 4, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['D', 4, 0],
        to: ['A', 4, -1],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['D', 4, 0],
        to: ['A', 4, 0],
        name: 'kwinta czysta'
    },
    {
        from: ['D', 4, 0],
        to: ['B', 4, -1],
        name: 'seksta mała'
    },
    {
        from: ['D', 4, 0],
        to: ['B', 4, 0],
        name: 'seksta wielka'
    },
    {
        from: ['D', 4, 0],
        to: ['C', 5, 0],
        name: 'septyma mała'
    },
    {
        from: ['D', 4, 0],
        to: ['C', 5, 1],
        name: 'septyma wielka'
    },
    {
        from: ['D', 4, 0],
        to: ['D', 5, 0],
        name: 'oktawa czysta'
    },
    //od dis
    {
        from: ['D', 4, 1],
        to: ['D', 4, 1],
        name: 'pryma czysta'
    },
    {
        from: ['D', 4, 1],
        to: ['E', 4, 0],
        name: 'sekunda mała'
    },
    {
        from: ['D', 4, 1],
        to: ['E', 4, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['D', 4, 1],
        to: ['F', 4, 1],
        name: 'tercja mała'
    },
    {
        from: ['D', 4, 1],
        to: ['F', 4, 2],
        name: 'tercja wielka'
    },
    {
        from: ['D', 4, 1],
        to: ['G', 4, 1],
        name: 'kwarta czysta'
    },
    {
        from: ['D', 4, 1],
        to: ['G', 4, 2],
        name: 'kwarta zwiększona'
    },
    {
        from: ['D', 4, 1],
        to: ['A', 4, 0],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['D', 4, 1],
        to: ['A', 4, 1],
        name: 'kwinta czysta'
    },
    {
        from: ['D', 4, 1],
        to: ['B', 4, 0],
        name: 'seksta mała'
    },
    {
        from: ['D', 4, 1],
        to: ['B', 4, 1],
        name: 'seksta wielka'
    },
    {
        from: ['D', 4, 1],
        to: ['C', 5, 1],
        name: 'septyma mała'
    },
    {
        from: ['D', 4, 1],
        to: ['C', 5, 2],
        name: 'septyma wielka'
    },
    {
        from: ['D', 4, 1],
        to: ['D', 5, 1],
        name: 'oktawa czysta'
    },
    //od e
    {
        from: ['E', 4, 0],
        to: ['E', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['E', 4, 0],
        to: ['F', 4, 0],
        name: 'sekunda mała'
    },
    {
        from: ['E', 4, 0],
        to: ['F', 4, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['E', 4, 0],
        to: ['G', 4, 0],
        name: 'tercja mała'
    },
    {
        from: ['E', 4, 0],
        to: ['G', 4, 1],
        name: 'tercja wielka'
    },
    {
        from: ['E', 4, 0],
        to: ['A', 4, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['E', 4, 0],
        to: ['A', 4, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['E', 4, 0],
        to: ['B', 4, -1],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['E', 4, 0],
        to: ['B', 4, 0],
        name: 'kwinta czysta'
    },
    {
        from: ['E', 4, 0],
        to: ['C', 5, 0],
        name: 'seksta mała'
    },
    {
        from: ['E', 4, 0],
        to: ['C', 5, 1],
        name: 'seksta wielka'
    },
    {
        from: ['E', 4, 0],
        to: ['D', 5, 0],
        name: 'septyma mała'
    },
    {
        from: ['E', 4, 0],
        to: ['D', 5, 1],
        name: 'septyma wielka'
    },
    {
        from: ['E', 4, 0],
        to: ['E', 5, 0],
        name: 'oktawa czysta'
    },
    //od f
    {
        from: ['F', 4, 0],
        to: ['F', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['F', 4, 0],
        to: ['G', 4, -1],
        name: 'sekunda mała'
    },
    {
        from: ['F', 4, 0],
        to: ['G', 4, 0],
        name: 'sekunda wielka'
    },
    {
        from: ['F', 4, 0],
        to: ['A', 4, -1],
        name: 'tercja mała'
    },
    {
        from: ['F', 4, 0],
        to: ['A', 4, 0],
        name: 'tercja wielka'
    },
    {
        from: ['F', 4, 0],
        to: ['B', 4, -1],
        name: 'kwarta czysta'
    },
    {
        from: ['F', 4, 0],
        to: ['B', 4, 0],
        name: 'kwarta zwiększona'
    },
    {
        from: ['F', 4, 0],
        to: ['C', 5, -1],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['F', 4, 0],
        to: ['C', 5, 0],
        name: 'kwinta czysta'
    },
    {
        from: ['F', 4, 0],
        to: ['D', 5, -1],
        name: 'seksta mała'
    },
    {
        from: ['F', 4, 0],
        to: ['D', 5, 0],
        name: 'seksta wielka'
    },
    {
        from: ['F', 4, 0],
        to: ['E', 5, -1],
        name: 'septyma mała'
    },
    {
        from: ['F', 4, 0],
        to: ['E', 5, 0],
        name: 'septyma wielka'
    },
    {
        from: ['F', 4, 0],
        to: ['F', 5, 0],
        name: 'oktawa czysta'
    },
    //od fis
    {
        from: ['F', 4, 1],
        to: ['F', 4, 1],
        name: 'pryma czysta'
    },
    {
        from: ['F', 4, 1],
        to: ['G', 4, 0],
        name: 'sekunda mała'
    },
    {
        from: ['F', 4, 1],
        to: ['G', 4, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['F', 4, 1],
        to: ['A', 4, 0],
        name: 'tercja mała'
    },
    {
        from: ['F', 4, 1],
        to: ['A', 4, 1],
        name: 'tercja wielka'
    },
    {
        from: ['F', 4, 1],
        to: ['B', 4, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['F', 4, 1],
        to: ['B', 4, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['F', 4, 1],
        to: ['C', 5, 0],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['F', 4, 1],
        to: ['C', 5, 1],
        name: 'kwinta czysta'
    },
    {
        from: ['F', 4, 1],
        to: ['D', 5, 0],
        name: 'seksta mała'
    },
    {
        from: ['F', 4, 1],
        to: ['D', 5, 1],
        name: 'seksta wielka'
    },
    {
        from: ['F', 4, 1],
        to: ['E', 5, 0],
        name: 'septyma mała'
    },
    {
        from: ['F', 4, 1],
        to: ['E', 5, 1],
        name: 'septyma wielka'
    },
    {
        from: ['F', 4, 1],
        to: ['F', 5, 1],
        name: 'oktawa czysta'
    },
    //od g
    {
        from: ['G', 4, 0],
        to: ['G', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['G', 4, 0],
        to: ['A', 4, -1],
        name: 'sekunda mała'
    },
    {
        from: ['G', 4, 0],
        to: ['A', 4, 0],
        name: 'sekunda wielka'
    },
    {
        from: ['G', 4, 0],
        to: ['B', 4, -1],
        name: 'tercja mała'
    },
    {
        from: ['G', 4, 0],
        to: ['B', 4, 0],
        name: 'tercja wielka'
    },
    {
        from: ['G', 4, 0],
        to: ['C', 5, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['G', 4, 0],
        to: ['C', 5, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['G', 4, 0],
        to: ['D', 5, -1],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['G', 4, 0],
        to: ['D', 5, 0],
        name: 'kwinta czysta'
    },
    {
        from: ['G', 4, 0],
        to: ['E', 5, -1],
        name: 'seksta mała'
    },
    {
        from: ['G', 4, 0],
        to: ['E', 5, 0],
        name: 'seksta wielka'
    },
    {
        from: ['G', 4, 0],
        to: ['F', 5, 0],
        name: 'septyma mała'
    },
    {
        from: ['G', 4, 0],
        to: ['F', 5, 1],
        name: 'septyma wielka'
    },
    {
        from: ['G', 4, 0],
        to: ['G', 5, 0],
        name: 'oktawa czysta'
    },
    //od gis
    {
        from: ['G', 4, 1],
        to: ['G', 4, 1],
        name: 'pryma czysta'
    },
    {
        from: ['G', 4, 1],
        to: ['A', 4, 0],
        name: 'sekunda mała'
    },
    {
        from: ['G', 4, 1],
        to: ['A', 4, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['G', 4, 1],
        to: ['B', 4, 0],
        name: 'tercja mała'
    },
    {
        from: ['G', 4, 1],
        to: ['B', 4, 1],
        name: 'tercja wielka'
    },
    {
        from: ['G', 4, 1],
        to: ['C', 5, 1],
        name: 'kwarta czysta'
    },
    {
        from: ['G', 4, 1],
        to: ['C', 5, 2],
        name: 'kwarta zwiększona'
    },
    {
        from: ['G', 4, 1],
        to: ['D', 5, 0],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['G', 4, 1],
        to: ['D', 5, 1],
        name: 'kwinta czysta'
    },
    {
        from: ['G', 4, 1],
        to: ['E', 5, 0],
        name: 'seksta mała'
    },
    {
        from: ['G', 4, 1],
        to: ['E', 5, 1],
        name: 'seksta wielka'
    },
    {
        from: ['G', 4, 1],
        to: ['F', 5, 1],
        name: 'septyma mała'
    },
    {
        from: ['G', 4, 1],
        to: ['F', 5, 2],
        name: 'septyma wielka'
    },
    {
        from: ['G', 4, 1],
        to: ['G', 5, 1],
        name: 'oktawa czysta'
    },
    //od a
    {
        from: ['A', 4, 0],
        to: ['A', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['A', 4, 0],
        to: ['B', 4, -1],
        name: 'sekunda mała'
    },
    {
        from: ['A', 4, 0],
        to: ['B', 4, 0],
        name: 'sekunda wielka'
    },
    {
        from: ['A', 4, 0],
        to: ['C', 5, 0],
        name: 'tercja mała'
    },
    {
        from: ['A', 4, 0],
        to: ['C', 5, 1],
        name: 'tercja wielka'
    },
    {
        from: ['A', 4, 0],
        to: ['D', 5, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['A', 4, 0],
        to: ['D', 5, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['A', 4, 0],
        to: ['E', 5, -1],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['A', 4, 0],
        to: ['E', 5, 0],
        name: 'kwinta czysta'
    },
    {
        from: ['A', 4, 0],
        to: ['F', 5, 0],
        name: 'seksta mała'
    },
    {
        from: ['A', 4, 0],
        to: ['F', 5, 1],
        name: 'seksta wielka'
    },
    {
        from: ['A', 4, 0],
        to: ['G', 5, 0],
        name: 'septyma mała'
    },
    {
        from: ['A', 4, 0],
        to: ['G', 5, 1],
        name: 'septyma wielka'
    },
    {
        from: ['A', 4, 0],
        to: ['A', 5, 0],
        name: 'oktawa czysta'
    },
    //od ais
    {
        from: ['A', 4, 1],
        to: ['A', 4, 1],
        name: 'pryma czysta'
    },
    {
        from: ['A', 4, 1],
        to: ['B', 4, 0],
        name: 'sekunda mała'
    },
    {
        from: ['A', 4, 1],
        to: ['B', 4, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['A', 4, 1],
        to: ['C', 5, 1],
        name: 'tercja mała'
    },
    {
        from: ['A', 4, 1],
        to: ['C', 5, 2],
        name: 'tercja wielka'
    },
    {
        from: ['A', 4, 1],
        to: ['D', 5, 1],
        name: 'kwarta czysta'
    },
    {
        from: ['A', 4, 1],
        to: ['D', 5, 2],
        name: 'kwarta zwiększona'
    },
    {
        from: ['A', 4, 1],
        to: ['E', 5, 0],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['A', 4, 1],
        to: ['E', 5, 1],
        name: 'kwinta czysta'
    },
    {
        from: ['A', 4, 1],
        to: ['F', 5, 1],
        name: 'seksta mała'
    },
    {
        from: ['A', 4, 1],
        to: ['F', 5, 2],
        name: 'seksta wielka'
    },
    {
        from: ['A', 4, 1],
        to: ['G', 5, 1],
        name: 'septyma mała'
    },
    {
        from: ['A', 4, 1],
        to: ['G', 5, 2],
        name: 'septyma wielka'
    },
    {
        from: ['A', 4, 1],
        to: ['A', 5, 1],
        name: 'oktawa czysta'
    },
    //od h
    {
        from: ['B', 4, 0],
        to: ['B', 4, 0],
        name: 'pryma czysta'
    },
    {
        from: ['B', 4, 0],
        to: ['C', 5, 0],
        name: 'sekunda mała'
    },
    {
        from: ['B', 4, 0],
        to: ['C', 5, 1],
        name: 'sekunda wielka'
    },
    {
        from: ['B', 4, 0],
        to: ['D', 5, 0],
        name: 'tercja mała'
    },
    {
        from: ['B', 4, 0],
        to: ['D', 5, 1],
        name: 'tercja wielka'
    },
    {
        from: ['B', 4, 0],
        to: ['E', 5, 0],
        name: 'kwarta czysta'
    },
    {
        from: ['B', 4, 0],
        to: ['E', 5, 1],
        name: 'kwarta zwiększona'
    },
    {
        from: ['B', 4, 0],
        to: ['F', 5, 0],
        name: 'kwinta zmniejszona'
    },
    {
        from: ['B', 4, 0],
        to: ['F', 5, 1],
        name: 'kwinta czysta'
    },
    {
        from: ['B', 4, 0],
        to: ['G', 5, 0],
        name: 'seksta mała'
    },
    {
        from: ['B', 4, 0],
        to: ['G', 5, 1],
        name: 'seksta wielka'
    },
    {
        from: ['B', 4, 0],
        to: ['A', 5, 0],
        name: 'septyma mała'
    },
    {
        from: ['B', 4, 0],
        to: ['A', 5, 1],
        name: 'septyma wielka'
    },
    {
        from: ['B', 4, 0],
        to: ['B', 5, 0],
        name: 'oktawa czysta'
    }
];