import {message} from "antd";

export const errorMessage = (error, history) => {
    let msg = 'Błąd ładowania danych';
    if (error.response && error.response.status === 401) {
        return history.push('/login');
    }
    message.error(msg);
}

export const updateErrorMessage = (error, history) => {
    let msg = 'Błąd zapisywania danych';
    if (error.response && error.response.status === 401) {
        return history.push('/login');
    }
    message.error(msg);
}

export const loginErrorMessage = (error) => {
    let msg = 'Błąd logowania';
    if (error.response && error.response.status === 401) {
        msg = 'Niepoprawna nazwa użytkownika lub hasło';
    }
    message.error(msg);
}

export const registerErrorMessage = (error) => {
    let msg = 'Błąd rejestracji';
    if (error.response) {
        console.log(error.response);
        if (error.response.data
            && error.response.data.validation_messages) {
            if (error.response.data.validation_messages.username
                && error.response.data.validation_messages.username.recordFound) {
                msg = 'Użytkownik o tej nazwie już istnieje';
            } else if (error.response.data.validation_messages.email
                && error.response.data.validation_messages.email.recordFound) {
                msg = 'Użytkownik z podanym adresem e-mail już istnieje';
            }
        }
    } else {
        msg = 'Błąd serwera';
    }
    message.error(msg);
}

export const recoverPasswordErrorMessage = (error) => {
    let msg = 'Błąd przywracania hasła';
    if (error.response) {
        if (error.response.data
            && error.response.data.validation_messages) {
            if (error.response.data.validation_messages.email
                && error.response.data.validation_messages.email.noRecordFound) {
                msg = 'Użytkownik z podanym adresem e-mail nie istnieje';
            }
        }
    }
    message.error(msg);
}

export const setPasswordErrorMessage = (error) => {
    let msg = 'Błąd ustawiania hasła';
    if (error.response) {
        if (error.response.data
            && error.response.data.validation_messages) {
            if (error.response.data.validation_messages.token
                && error.response.data.validation_messages.token.noRecordFound) {
                msg = 'Błędny token';
            }
        }
    }
    message.error(msg);
}
