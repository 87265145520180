import {Col, Layout, Row} from "antd";
import ExercisesNavbar from "./ExercisesNavbar";

const {Content} = Layout;

export const ExerciseLayout = ({children, ...rest}) => {
    return (
        <Row>
            <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
                    <Content style={{padding: '12px'}}>
                        <ExercisesNavbar/>
                    </Content>
                </Layout>
            </Col>
            <Col xs={24} sm={24} md={14} lg={18} xl={18}>
                {children}
            </Col>
        </Row>
    )
}

export default ExerciseLayout;