import React, {Component} from 'react';
import {Button, Layout, Typography, Card} from "antd";
import MIDISounds from 'midi-sounds-react';
import {CaretRightOutlined, StopOutlined} from '@ant-design/icons';
import {intervals} from './intervals';
import {drawExercise, intervalNames, playCorrect, playIncorrect} from "./helper";
import {MidiPlayer} from "./MidiPlayer";

const {Content} = Layout;

const exercises = intervals.filter(interval => interval.name !== 'kwarta zwiększona');
const responses = intervalNames.filter(interval => interval.name !== 'kwarta zwiększona');

export default class HarmonicIntervalsTestContainer extends Component {

    state = {
        current: null,
        result: 0,
        exerciseNo: 0
    }

    start() {
        this.setState({current: drawExercise(exercises)});
    }

    stop() {
        this.setState({current: null, exerciseNo: 0, result: 0});
    }

    check(value) {
        let {result, exerciseNo} = this.state;
        if (this.state.current.name === value.name) {
            playCorrect();
            result++;
        } else {
            playIncorrect();
            alert('Prawidłowa odpowiedź to: ' + this.state.current.name);
        }
        exerciseNo++;
        this.setState({result, exerciseNo, current: drawExercise(exercises)});
    }

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '20px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Interwały harmoniczne. Test audio
                    </Typography.Title>

                    <div style={{display: 'none'}}>
                        <MIDISounds
                            ref={(ref) => (this.midiSounds = ref)}
                            appElementName="root" instruments={[3]}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        justifyContent: 'center',
                        marginTop: '20px'
                    }}>

                        {
                            this.state.current ?
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <Card title={'Wynik'} style={{
                                        width: '250px',
                                        fontSize: '36px',
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                    }}
                                          bordered={true}
                                    >
                                        {this.state.result} / {this.state.exerciseNo}
                                    </Card>
                                    <Typography.Title level={4} style={{margin: '0'}}>
                                        Jaki to interwał?
                                    </Typography.Title>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '5px'
                                    }}>
                                        <Button type="primary"
                                                icon={<CaretRightOutlined/>}
                                                onClick={() => MidiPlayer.playInterval(this.midiSounds, this.state.current, true)}>
                                            Odsłuchaj
                                        </Button>
                                        <Button icon={<StopOutlined/>}
                                                onClick={() => this.stop()}>
                                            Zakończ
                                        </Button>
                                    </div>
                                    <p style={{marginTop: '10px'}}>Wskaż prawidłową odpowiedź:</p>
                                    <div style={{
                                        padding: '10px 10px',
                                        display: 'flex',
                                        gap: '10px',
                                        flexWrap: 'wrap',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {
                                            responses.map(interval => (
                                                <Card key={interval.name}
                                                      style={{width: '150px', fontSize: '16px', textAlign: 'center'}}
                                                      hoverable={true}
                                                      bordered={true}
                                                      onClick={() => this.check(interval)}
                                                      title={interval.code}
                                                >
                                                    {interval.name}
                                                </Card>
                                            ))
                                        }
                                    </div>
                                </div> :
                                <Button type="primary"
                                        onClick={() => this.start()}>
                                    Start
                                </Button>
                        }
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
