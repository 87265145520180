import React, {Component} from "react";
import {Form, Input, Button} from 'antd';
import {Link} from 'react-router-dom'
import {validatePassword} from "../../validators/password";

class SetPasswordForm extends Component {

    formRef = React.createRef()

    handleSubmit = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                this.props.handleSubmit(values, () => {
                    this.formRef.current.resetFields()
                });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    state = {
        confirmDirty: false,
    };

    handleConfirmBlur = e => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <React.Fragment>
                <h1 style={{
                    margin: '0 auto',
                    background: 'none',
                    padding: '2em 0 1em 0',
                    textAlign: 'center'
                }}>Zmiana hasła</h1>

                <Form {...formItemLayout}
                      onFinish={this.handleSubmit}
                      ref={this.formRef}>

                    <p>
                        Ustaw nowe, bezpieczne hasło
                        (minimum 8 znaków, w tym przynajmniej jedna mała, jedna duża litera i jedna cyfra)
                    </p>

                    <Form.Item name="password"
                               label="Hasło"
                               hasFeedback
                               rules={[
                                   {
                                       required: true,
                                       message: 'Proszę wprowadzić hasło',
                                   },
                                   {
                                       min: 8,
                                       message: 'Hasło musi mieć przynajmniej 8 znaków'
                                   },
                                   {
                                       validator: validatePassword,
                                   },
                               ]}>
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item name="confirm"
                               label="Potwierdź hasło"
                               hasFeedback
                               rules={[
                                   {
                                       required: true,
                                       message: 'Proszę ponownie wprowadzić hasło',
                                   },
                                   ({getFieldValue}) => ({
                                       validator(_, value) {
                                           if (!value || getFieldValue('password') === value) {
                                               return Promise.resolve();
                                           }

                                           return Promise.reject(new Error('Wprowadzone hasła są różne od siebie'));
                                       },
                                   })
                               ]}>
                        <Input.Password onBlur={this.handleConfirmBlur}/>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Zapisz
                        </Button>
                        <Link to="/login" style={{marginLeft: '1em'}}>
                            Zaloguj się
                        </Link>
                    </Form.Item>

                </Form>
            </React.Fragment>
        );
    }
};

export default SetPasswordForm;
