import React, {Component} from "react";
import SetPasswordForm from "./SetPasswordForm";

export class SetPasswordContainer extends Component {

    handleSubmit = (data, onSuccess) => {
        data.token = this.props.match.params.token;
        delete data.confirm;
        this.props.setPassword(data, this.props.history, onSuccess);
    }

    render() {
        return <SetPasswordForm
                handleSubmit={this.handleSubmit}
            />;
    }
}
