export const minorScale = [ 
    {
        name: 'a-moll',
        notes: [['A', 3, 0], ['B', 3, 0], ['C', 4, 0], ['D', 4, 0], ['E', 4, 0], ['F', 4, 0], ['G', 4, 0], ['A', 4, 0]],
        fifths: 0
    },
    {
        name: 'e-moll',
        notes: [['E', 4, 0], ['F', 4, 1], ['G', 4, 0], ['A', 4, 0], ['B', 4, 0], ['C', 5, 0], ['D', 5, 0], ['E', 5, 0]],
        fifths: 1
    },
    {
        name: 'h-moll',
        notes: [['B', 3, 0], ['C', 4, 1], ['D', 4, 0], ['E', 4, 0], ['F', 4, 1], ['G', 4, 0], ['A', 4, 0], ['B', 4, 0]],
        fifths: 2
    },
    {
        name: 'fis-moll',
        notes: [['F', 4, 1], ['G', 4, 1], ['A', 4, 0], ['B', 4, 0], ['C', 5, 1], ['D', 5, 0], ['E', 5, 0], ['F', 5, 1]],
        fifths: 3
    },
    {
        name: 'cis-moll',
        notes: [['C', 4, 1], ['D', 4, 1], ['E', 4, 0], ['F', 4, 1], ['G', 4, 1], ['A', 4, 0], ['B', 4, 0], ['C', 5, 1]],
        fifths: 4
    },
    {
        name: 'gis-moll',
        notes: [['G', 4, 1], ['A', 4, 1], ['B', 4, 0], ['C', 5, 1], ['D', 5, 1], ['E', 5, 0], ['F', 5, 1], ['G', 5, 1]],
        fifths: 5
    },
    {
        name: 'dis-moll',
        notes: [['D', 4, 1], ['E', 4, 1], ['F', 4, 1], ['G', 4, 1], ['A', 4, 1], ['B', 4, 0], ['C', 5, 1], ['D', 5, 1]],
        fifths: 6
    },
    {
        name: 'ais-moll',
        notes: [['A', 3, 1], ['B', 3, 1], ['C', 4, 1], ['D', 4, 1], ['E', 4, 1], ['F', 4, 1], ['G', 4, 1], ['A', 4, 1]],
        fifths: 7
    },
    {
        name: 'd-moll',
        notes: [['D', 4, 0], ['E', 4, 0], ['F', 4, 0], ['G', 4, 0], ['A', 4, 0], ['B', 4, -1], ['C', 5, 0], ['D', 5, 0]],
        fifths: -1
    },
    {
        name: 'g-moll',
        notes: [['G', 4, 0], ['A', 4, 0], ['B', 4, -1], ['C', 5, 0], ['D', 5, 0], ['E', 5, -1], ['F', 5, 0], ['G', 5, 0]],
        fifths: -2
    },
    {
        name: 'c-moll',
        notes: [['C', 4, 0], ['D', 4, 0], ['E', 4, -1], ['F', 4, 0], ['G', 4, 0], ['A', 4, -1], ['B', 4, -1], ['C', 5, 0]],
        fifths: -3
    },
    {
        name: 'f-moll',
        notes: [['F', 4, 0], ['G', 4, 0], ['A', 4, -1], ['B', 4, -1], ['C', 5, 0], ['D', 5, -1], ['E', 5, -1], ['F', 5, 0]],
        fifths: -4
    },
    {
        name: 'b-moll',
        notes: [['B', 3, -1], ['C', 4, 0], ['D', 4, -1], ['E', 4, -1], ['F', 4, 0], ['G', 4, -1], ['A', 4, -1], ['B', 4, -1]],
        fifths: -5
    },
    {
        name: 'es-moll',
        notes: [['E', 4, -1], ['F', 4, 0], ['G', 4, -1], ['A', 4, -1], ['B', 4, -1], ['C', 5, -1], ['D', 5, -1], ['E', 5, -1]],
        fifths: -6
    },
    {
        name: 'as-moll',
        notes: [['A', 3, -1], ['B', 3, -1], ['C', 4, -1], ['D', 4, -1], ['E', 4, -1], ['F', 4, -1], ['G', 4, -1], ['A', 4, -1]],
        fifths: -7
    }
];