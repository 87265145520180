import React from 'react';
import {Result, Button} from 'antd';

export const MsczView = (props) => {
    return <div>
        <Result
            status="warning"
            title="Pliku mscz nie można na razie otworzyć w przeglądarce."
            subTitle="Można go pobrać i otworzyć za pomocą programu Musescore."
            extra={
                <Button type="primary" key="download" onClick={props.download}>
                    Pobierz
                </Button>
            }
        />
    </div>
}
