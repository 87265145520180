import React, {Component} from 'react';
import {Menu} from "antd";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

class ExercisesNavbar extends Component {

    render() {
        const {location} = this.props;
        const parts = location.pathname.split('/');
        const root = '/' + parts[1];
        let current = '';
        for (let i = 1; i < parts.length; i++) {
            current += '/' + parts[i];
        }
        return <Menu theme="light"
                     selectedKeys={[root, current]}
                     mode="inline">
            <Menu.SubMenu
                title="Dźwięki">
                <Menu.Item
                    key="/exercises/clefs">
                    <Link to="/exercises/clefs">
                        Klucze
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/note-values">
                    <Link to="/exercises/note-values">
                        Wartości rytmiczne
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/note-values/test">
                    <Link to="/exercises/note-values/test">
                        Wartości rytmiczne. Test
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/notes">
                    <Link to="/exercises/notes">
                        Nazwy dźwięków
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/notes/test">
                    <Link to="/exercises/notes/test">
                        Nazwy dźwięków. Test
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
                title="Interwały">
                <Menu.Item
                    key="/exercises/intervals">
                    <Link to="/exercises/intervals">
                        Nazwy interwałów
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/intervals/test">
                    <Link to="/exercises/intervals/test">
                        Nazwy interwałów. Test
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/intervals/test/melodic">
                    <Link to="/exercises/intervals/test/melodic">
                        Interwały melodyczne. Test audio
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/intervals/test/harmonic">
                    <Link to="/exercises/intervals/test/harmonic">
                        Interwały harmoniczne. Test audio
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/intervals/inversions">
                    <Link to="/exercises/intervals/inversions">
                        Przewroty interwałów
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/intervals/inversions/test">
                    <Link to="/exercises/intervals/inversions/test">
                        Przewroty interwałów. Test
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
                title="Trójdźwięki">
                <Menu.Item
                    key="/exercises/triads">
                    <Link to="/exercises/triads">
                        Rodzaje trójdźwięków
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/triads/test">
                    <Link to="/exercises/triads/test">
                        Trójdźwięki. Test
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/triads/inversions">
                    <Link to="/exercises/triads/inversions">
                        Przewroty trójdźwięków
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/triads/inversions/test">
                    <Link to="/exercises/triads/inversions/test">
                        Przewroty trójdźwięków. Test
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
                title="Skale i gamy">
                <Menu.Item
                    key="/exercises/major-scale">
                    <Link to="/exercises/major-scale">
                        Skala durowa
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/minor-scale">
                    <Link to="/exercises/minor-scale">
                        Skala molowa
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/keys">
                    <Link to="/exercises/keys">
                        Tonacje
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/exercises/keys/test">
                    <Link to="/exercises/keys/test">
                        Tonacje. Test
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>;
    }
}

const RouterExercisesNavbar = withRouter(ExercisesNavbar);

const mapStateToProps = (state) => ({
    settings: state.Settings
});

export default connect(mapStateToProps, {})(RouterExercisesNavbar);
