export const keys = [
    {
        fifths: 0,
        name: 'C-dur / a-moll'
    },
    {
        fifths: 1,
        name: 'G-dur / e-moll'
    },
    {
        fifths: 2,
        name: 'D-dur / h-moll'
    },
    {
        fifths: 3,
        name: 'A-dur / fis-moll'
    },
    {
        fifths: 4,
        name: 'E-dur / cis-moll'
    },
    {
        fifths: 5,
        name: 'H-dur / gis-moll'
    },
    {
        fifths: 6,
        name: 'Fis-dur / dis-moll'
    },
    {
        fifths: 7,
        name: 'Cis-dur / ais-moll'
    },
    {
        fifths: -1,
        name: 'F-dur / d-moll'
    },
    {
        fifths: -2,
        name: 'B-dur / g-moll'
    },
    {
        fifths: -3,
        name: 'Es-dur / c-moll'
    },
    {
        fifths: -4,
        name: 'As-dur / f-moll'
    },
    {
        fifths: -5,
        name: 'Des-dur / b-moll'
    },
    {
        fifths: -6,
        name: 'Ges-dur / es-moll'
    },
    {
        fifths: -7,
        name: 'Ces-dur / as-moll'
    }
];