import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

export const Audio = (props) => {
    return <ReactAudioPlayer
        src={props.file}
        autoPlay
        controls
        style={{width: '100%'}}
    />;
}
