import React, {Component} from "react";
import {Tag, Tooltip, Badge, Space} from "antd";
import {
    YoutubeOutlined,
    FileOutlined,
    BookOutlined
} from '@ant-design/icons';

export class CompositionTags extends Component {
    render() {
        const {composition} = this.props;
        return <React.Fragment>
            <Space>
                {
                    composition.links.length ?
                        <Tooltip title="Linki do Youtube">
                            <Badge count={composition.links.length}>
                                <Tag icon={<YoutubeOutlined/>} color="#cd201f">
                                    Youtube
                                </Tag>
                            </Badge>
                        </Tooltip> : null
                }
                {
                    composition.files.length ?
                        <Tooltip title="Pliki z nutami">
                            <Badge count={composition.files.length}>
                                <Tag icon={<FileOutlined/>} color="#3366ff">
                                    Pliki
                                </Tag>
                            </Badge>
                        </Tooltip> : null
                }
                {
                    composition.source_name ?
                        <Tooltip title={composition.source_name}>
                            <Tag icon={<BookOutlined/>} color="#aaaaaa">
                                Źródło
                            </Tag>
                        </Tooltip> : null
                }
            </Space>
        </React.Fragment>
    }
}
