import React, {Component} from 'react';
import {Button, Layout, Typography, Card} from "antd";
import {StopOutlined} from '@ant-design/icons';
import {intervals} from './intervals';
import {
    drawExercise,
    getInversion,
    intervalNames,
    playCorrect,
    playIncorrect
} from "./helper";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import {MusicXml} from "./MusicXml";

const {Content} = Layout;

const exercises = intervals.filter(item => Math.abs(item.from[2]) !== 2 && Math.abs(item.to[2]) !== 2);

export default class IntervalInversionsTestContainer extends Component {

    state = {
        current: null,
        result: 0,
        exerciseNo: 0
    }

    start() {
        this.setState({current: drawExercise(exercises)});
    }

    stop() {
        this.setState({current: null, exerciseNo: 0, result: 0});
    }

    check(value) {
        let {result, exerciseNo} = this.state;
        const inversion = getInversion(this.state.current);
        if (inversion === value.name) {
            playCorrect();
            result++;
        } else {
            playIncorrect();
            alert('Prawidłowa odpowiedź to: ' + inversion);
        }
        exerciseNo++;
        this.setState({result, exerciseNo, current: drawExercise(exercises)});
    }

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '20px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Przewroty interwałów. Test
                    </Typography.Title>

                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        justifyContent: 'center',
                        marginTop: '20px'
                    }}>

                        {
                            this.state.current ?
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <Card title={'Wynik'} style={{
                                        width: '250px',
                                        fontSize: '36px',
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                    }}
                                          bordered={true}
                                    >
                                        {this.state.result} / {this.state.exerciseNo}
                                    </Card>
                                    <Typography.Title level={4} style={{margin: '0'}}>
                                        Co będzie przewrotem podanego interwału?
                                    </Typography.Title>
                                    <div style={{width: '300px'}}>
                                        <OpenSheetMusicDisplay
                                            file={MusicXml.buildIntervalMusicXML(this.state.current, true)}
                                            drawTitle={false}
                                            autoResize={true}
                                            backend={'svg'}
                                            drawPartNames={false}
                                            drawCredits={false}
                                            stretchLastSystemLine={true}
                                        />
                                    </div>
                                    <Button icon={<StopOutlined/>}
                                            onClick={() => this.stop()}>
                                        Zakończ
                                    </Button>
                                    <p style={{marginTop: '10px'}}>Wskaż prawidłową odpowiedź:</p>
                                    <div style={{
                                        padding: '10px 10px',
                                        display: 'flex',
                                        gap: '10px',
                                        flexWrap: 'wrap',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {
                                            intervalNames.map(interval => (
                                                <Card key={interval.name}
                                                      style={{width: '150px', fontSize: '16px', textAlign: 'center'}}
                                                      hoverable={true}
                                                      bordered={true}
                                                      onClick={() => this.check(interval)}
                                                      title={interval.code}
                                                >
                                                    {interval.name}
                                                </Card>
                                            ))
                                        }
                                    </div>
                                </div> :
                                <Button type="primary"
                                        onClick={() => this.start()}>
                                    Start
                                </Button>
                        }
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
