import React, {Component} from "react";
import {connect} from "react-redux";
import {
    loadingError,
    startLoader,
    printRepertoire,
    getEvent,
    downloadFile,
    downloadFileError
} from "../../data/actionCreators";
import EventContainer from "./EventContainer";
import {Spin} from "antd";
import {errorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    loadEvent: (id, part, history, onSuccess) => {
        //dispatch(startLoader('Pobieranie danych wydarzenia'));
        dispatch(getEvent(id, part, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    printRepertoire: (id, part, history) => {
        dispatch(startLoader('Drukowanie repertuaru'));
        dispatch(printRepertoire(id, part, (data) => {
                    const file = new Blob(
                        [data],
                        {type: 'application/pdf'});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }, (error) => {
                    dispatch(loadingError(error));
                    errorMessage(error, history);
                }
            )
        );
    },
    downloadFile: (file, history) => {
        dispatch(startLoader('Pobieranie pliku'));
        dispatch(downloadFile(file, (error) => {
            dispatch(downloadFileError(error));
            errorMessage(error, history);
            //console.log(error);
        }));
    },
});

export const EventConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        render() {
            return <Spin spinning={this.props.loading} tip={this.props.text}>
                <EventContainer {...this.props}/>
            </Spin>
        }
    }
);
