import React, {Component} from 'react';
import {Layout, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import {keys} from './keys';
import {intervalNames} from "./helper";
import {MusicXml} from "./MusicXml";

const {Content} = Layout;

export default class KeysContainer extends Component {

    getCode = (item) => {
        const index = intervalNames.findIndex(interval => interval.name === item.name);
        if (index >= 0) {
            return intervalNames[index].code;
        }
        return '';
    }

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Tonacje
                    </Typography.Title>

                    <p style={{marginTop: '16px'}}>
                        Tonacja – przynależność materiału dźwiękowego utworu muzycznego do konkretnej gamy durowej lub
                        molowej, na której jest on oparty. Tonację utworu (lub jego fragmentu) określa się, biorąc pod
                        uwagę znaki przykluczowe oraz akordy lub dźwięki zaczynające i kończące utwór
                    </p>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {keys.map((item, key) => {
                            return <div key={key} style={{width: '300px', textAlign: 'center'}}>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildKeyMusicXML(item)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <div>{item.name}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
