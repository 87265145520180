import React, {Component} from "react";
import {Layout} from "antd";
import Calendar from "../../components/calendar/Calendar";
import {DataTypes, Lists} from "../../data/types";
import {EventModal} from "./EventModal";
import {amIAdmin} from "../../helpers/auth";
//import moment from 'moment';

const emptyEvent = {
    type_id: null,
    start: null,
    end: null,
    description: ''
}

class CalendarContainer extends Component {
    state = {
        eventModalVisible: false,
        eventModalTitle: 'Edycja wydarzenia',
        event: emptyEvent,
        searching: false
    }

    componentDidMount() {
        this.props.loadList(Lists.EVENT_TYPES, this.props.history);
    }

    onCalendarChange = (start, end) => {
        const params = this.props[`${DataTypes.FEASTS}_params`];
        params.start = start;
        params.end = end;
        this.props.loadCollection(DataTypes.FEASTS, params, this.props.history);

        const params2 = this.props[`${DataTypes.EVENTS}_params`];
        params2.start = start;
        params2.end = end;
        this.props.loadCollection(DataTypes.EVENTS, params2, this.props.history);
    }

    onDayClick = (e, day) => {
        if (amIAdmin()) {
            console.log("SELECTED DAY: ", day);
            this.setState({
                eventModalVisible: true,
                eventModalTitle: 'Nowe wydarzenie',
                event: {...emptyEvent, start: day, end: day}
            });
        }
    }

    onEventModalClose = () => {
        this.setState({eventModalVisible: false, event: {}});
    }

    onEventSave = (id, values) => {
        //console.log(id, values);
        this.setState({eventModalVisible: false, event: {}}, () => {
            values.start = values.start.format('YYYY-MM-DD HH:mm');
            values.end = values.end.format('YYYY-MM-DD HH:mm');
            this.props.saveEvent(DataTypes.EVENTS, id, values, this.props[`${DataTypes.EVENTS}_params`], this.props.history);
        });
    }

    onEventClick = (event) => {
        this.props.loadEntity(DataTypes.EVENTS, event.id, this.props.history, (data) => {
            this.setState({
                eventModalVisible: true,
                eventModalTitle: 'Wydarzenie nr ' + event.id,
                event: data
            });
        });
    }

    /*onChangeEventType = (eventTypeId) => {
        //console.log(eventTypeId);
        const params = this.props[`${DataTypes.EVENT_PARTS}_params`];
        console.log(params);
        params.event_type_id = eventTypeId;
        this.props.loadCollection(DataTypes.EVENT_PARTS, params);
    }*/

    goToRepertoire = () => {
        this.props.history.push('/repertoire/' + this.state.event.id);
    }

    render() {
        //console.log(this.props.lists[Lists.EVENT_TYPES]);
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Calendar
                id="liturgicalCalendar"
                feasts={this.props.feasts}
                events={this.props.events}
                onChange={this.onCalendarChange}
                onDayClick={this.onDayClick}
                onEventClick={this.onEventClick}
            />
            <EventModal
                visible={this.state.eventModalVisible}
                title={this.state.eventModalTitle}
                data={this.state.event}
                handleOk={this.onEventSave}
                handleCancel={this.onEventModalClose}
                handleRepertoire={this.goToRepertoire}
                eventTypes={this.props.lists[Lists.EVENT_TYPES]}
            />
        </Layout>;
    }
}

export default CalendarContainer;
