import ExerciseLayoutRoute, {ExerciseLayout} from "./ExerciseLayout";
import {Route} from "react-router-dom";
import ClefsContainer from "./ClefsContainer";
import NotesContainer from "./NotesContainer";
import NotesTestContainer from "./NotesTestContainer";
import NoteValuesContainer from "./NoteValuesContainer";
import NoteValuesTestContainer from "./NoteValuesTestContainer";
import IntervalsContainer from "./IntervalsContainer";
import IntervalsTestContainer from "./IntervalsTestContainer";
import MelodicIntervalsTestContainer from "./MelodicIntervalsTestContainer";
import HarmonicIntervalsTestContainer from "./HarmonicIntervalsTestContainer";
import IntervalInversionsContainer from "./IntervalInversionsContainer";
import IntervalInversionsTestContainer from "./IntervalInversionsTestContainer";
import TriadsContainer from "./TriadsContainer";
import HarmonicTriadsTestContainer from "./HarmonicTriadsTestContainer";
import TriadlInversionsContainer from "./TriadInversionsContainer";
import HarmonicTriadInversionsTestContainer from "./HarmonicTriadInversionsTestContainer";
import KeysContainer from "./KeysContainer";
import KeysTestContainer from "./KeysTestContainer";
import MajorScaleContainer from "./MajorScaleContainer";
import MinorScaleContainer from "./MinorScaleContainer";

const routes = [
    {
        path: "/clefs",
        component: ClefsContainer,
        layout: ExerciseLayoutRoute,
    },
    {
        path: "/notes",
        exact: true,
        component: NotesContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/notes/test",
        component: NotesTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/note-values",
        exact: true,
        component: NoteValuesContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/note-values/test",
        component: NoteValuesTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/intervals",
        exact: true,
        component: IntervalsContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/intervals/test",
        exact: true,
        component: IntervalsTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/intervals/test/melodic",
        exact: true,
        component: MelodicIntervalsTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/intervals/test/harmonic",
        exact: true,
        component: HarmonicIntervalsTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/intervals/inversions",
        exact: true,
        component: IntervalInversionsContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/intervals/inversions/test",
        exact: true,
        component: IntervalInversionsTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/triads",
        exact: true,
        component: TriadsContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/triads/test",
        exact: true,
        component: HarmonicTriadsTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/triads/inversions",
        exact: true,
        component: TriadlInversionsContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/triads/inversions/test",
        exact: true,
        component: HarmonicTriadInversionsTestContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/major-scale",
        exact: true,
        component: MajorScaleContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/minor-scale",
        exact: true,
        component: MinorScaleContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/keys",
        exact: true,
        component: KeysContainer,
        layout: ExerciseLayoutRoute
    },
    {
        path: "/keys/test",
        component: KeysTestContainer,
        layout: ExerciseLayoutRoute
    }
]
export const Exercises = () => {
    return (
        <ExerciseLayout>
            {
                routes.map(route => {
                    const {path, exact, layout, ...otherProps} = route;
                    return (
                        <Route
                            exact={exact === false ? false : true}
                            key={path}
                            path={'/exercises' + path}
                            {...otherProps}
                        >
                        </Route>
                    );
                })
            }
        </ExerciseLayout>
    )
}