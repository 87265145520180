import React, {Component} from 'react';
import {SortableList} from "../calendar/SortableList";
import {Button, Form, Layout, Select, Input, Typography, Row, Col, Space} from "antd";
import {CompositionInput} from "../../components/compositionInput/CompositionInput";
import {DataTypes} from "../../data/types";
import moment from "moment";
import {amIAdmin} from "../../helpers/auth";
import {parts} from "../../data/fileTypes";
import {ShareAltOutlined} from "@ant-design/icons";

const {Content} = Layout;

const layout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 18
    },
};

const btnLayout = {
    wrapperCol: {
        offset: 6,
        span: 18
    }
}

export default class RepertoireContainer extends Component {

    formRef = React.createRef();

    state = {
        searching: false,
        event: {},
        compositions: [],
        eventParts: [],
        part: 10
    }

    componentDidMount() {
        this.props.loadEntity(DataTypes.EVENTS, this.props.match.params.id, this.props.history, (event) => {
            this.setState({event: event});
            this.props.loadCollection(DataTypes.EVENT_PARTS, {
                event_type_id: event.type_id,
                limit: 50
            }, this.props.history, (eventParts) => {
                this.setState({eventParts});
            });
        });
    }

    searchCompositions = (search) => {
        //console.log(search);
        this.setState({searching: true});
        this.props.searchCompositions(search, this.props.history, (data) => {
            this.setState({compositions: data, searching: false});
        });
    }

    addItem = (values) => {
        values.composition_id = values.composition_id.id;
        const {compositions} = this.state.event;
        compositions.push(values);
        //console.log(event, values);
        this.props.saveEvent(DataTypes.EVENTS, this.state.event.id, {compositions}, this.props[`${DataTypes.EVENTS}_params`], this.props.history, (event) => {
            this.setState({event: event});
            this.formRef.current.resetFields();
        });
    }

    printRepertoire = () => {
        this.props.printRepertoire(this.state.event.id, this.state.part, this.props.history);
    }

    onSortEnd = (compositions) => {
        this.props.saveEvent(DataTypes.EVENTS, this.state.event.id, {compositions}, this.props[`${DataTypes.EVENTS}_params`], this.props.history, (event) => {
            this.setState({event: event});
        });
    };

    onRemove = (compositions) => {
        this.props.saveEvent(DataTypes.EVENTS, this.state.event.id, {compositions}, this.props[`${DataTypes.EVENTS}_params`], this.props.history, (event) => {
            this.setState({event: event});
        });
    }

    onPartChange = (value) => {
        this.setState({part: value});
    }

    renderPartOptions() {
        /*const parts = [
            {id: 10, name: 'Chór'},
            {id: 13, name: 'Flet'},
            {id: 26, name: 'Klarnet'},
            {id: 33, name: 'Obój'},
            {id: 55, name: 'Trąbka'},
            {id: 44, name: 'Skrzypce I'},
            {id: 45, name: 'Skrzypce II'},
            {id: 6, name: 'Altówka'},
            {id: 60, name: 'Wiolonczela'},
            {id: 30, name: 'Kontrabas'},
            {id: 20, name: 'Gitara'},
            {id: 35, name: 'Partytura'}
        ];*/
        return parts.map(part => <Select.Option key={part.id} value={part.id}>{part.name}</Select.Option>);
    }

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '1em', minHeight: 280}}>

                <Row>
                    <Col xs={24} sm={20}>
                        <Typography.Title level={2}>
                            Repertuar
                        </Typography.Title>
                    </Col>
                    <Col xs={24} sm={4} style={{textAlign: 'right'}}>
                        <a title={'Udostępnij link do repertuaru'} target="_blank" rel="noreferrer" href={'/e/'+this.props.match.params.id}>
                            <ShareAltOutlined /> Udostępnij
                        </a>
                    </Col>
                </Row>

                <Typography.Title level={4} style={{textTransform: 'capitalize', marginBottom: '1em'}}>
                    {this.state.event.type_name} {this.state.event.start ? moment(this.state.event.start).format('DD.MM.YYYY H:mm') : ''}
                </Typography.Title>

                {this.state.event.compositions && <SortableList
                    data={this.state.event.compositions}
                    onRemove={this.onRemove}
                    onSortEnd={this.onSortEnd}
                />}
                {amIAdmin() &&
                <Form
                    {...layout}
                    style={{marginTop: '2em'}}
                    onFinish={this.addItem}
                    ref={this.formRef}
                    initialValues={{
                        composition_id: {
                            id: 0,
                            text: '',
                            record: {},
                        },
                    }}
                >

                    <Form.Item
                        name="event_part_id"
                        label="Część"
                        rules={[{required: true, message: 'Wybierz z listy część wydarzenia'}]}
                    >
                        <Select>
                            {
                                this.state.eventParts.map(eventPart => {
                                        return <Select.Option key={eventPart.id}
                                                              value={eventPart.id}>
                                            {eventPart.name}
                                        </Select.Option>;
                                    }
                                )
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="composition_id"
                        label="Utwór"
                    >
                        <CompositionInput
                            searching={this.state.searching}
                            onSearch={this.searchCompositions}
                            data={this.state.compositions}
                        />
                    </Form.Item>

                    <Form.Item
                        name="info"
                        label="Uwagi"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item {...btnLayout}>
                        <Button type="primary" htmlType="submit">
                            Dodaj
                        </Button>
                    </Form.Item>
                </Form>
                }

                <div style={{marginTop: '2em'}}>
                    <Space>
                        <Select
                            style={{width: 200}}
                            value={this.state.part}
                            onChange={this.onPartChange}
                        >
                            {this.renderPartOptions()}
                        </Select>
                        <Button type="primary"
                                onClick={this.printRepertoire}
                        >
                            Pobierz
                        </Button>
                    </Space>
                </div>

            </Content>
        </Layout>
    }
}
