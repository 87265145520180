import React, {Component} from "react";
import {Button, Col, Row, Input, Space} from "antd";
import {
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    ReloadOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {DataTypes} from "../../data/types";
import {CompositionFilterModal} from "./CompositionFilterModal";
import {CompositionEditModal} from "./CompositionEditModal";
import {amIAdmin} from "../../helpers/auth";

const emptyComposition = {
    id: null,
    title: '',
    categories: [],
    lyrics_author: '',
    composer: '',
    arranger: '',
    source_id: null,
    lyrics: '',
    description: ''
};
export class SongbookToolbar extends Component {

    state = {
        fullText: '',
        filterModalVisible: false,
        editModalTitle: '',
        editModalVisible: false,
        composition: emptyComposition
    };

    filterFullText = (text) => {
        const params = {
            ...this.props[`${DataTypes.COMPOSITIONS}_params`],
            page: 1,
            fulltext: text
        };
        this.props.loadCollection(DataTypes.COMPOSITIONS, params, this.props.history);
    }

    handleResetFullText = () => {
        this.setState({fullText: ''}, () => {
            this.filterFullText('');
        });
    };

    handleKeyDownFullText = (e) => {
        if (e.key === 'Enter') {
            this.filterFullText(this.state.fullText);
        }
    }

    refresh = () => {
        this.props.loadCollection(DataTypes.COMPOSITIONS, this.props[`${DataTypes.COMPOSITIONS}_params`], this.props.history);
    }

    showFilterModal = () => {
        this.setState({filterModalVisible: true});
    }

    onFilterOk = (values) => {
        //console.log(values);
        const params = {
            ...this.props[`${DataTypes.COMPOSITIONS}_params`],
            page: 1,
        };
        for (var i in values) {
            params[i] = values[i] || '';
        }
        this.props.loadCollection(DataTypes.COMPOSITIONS, params, this.props.history);
        this.setState({filterModalVisible: false});
    }

    onFilterCancel = () => {
        this.setState({filterModalVisible: false});
    }

    showAddModal = () => {
        this.setState({
            editModalVisible: true,
            editModalTitle: 'Nowy utwór',
            composition: emptyComposition
        });
    }

    showEditModal = () => {
        this.setState({
            editModalVisible: true,
            editModalTitle: this.props.compositions_entity.id + '. ' + this.props.compositions_entity.title,
            composition: this.props.compositions_entity
        });
    }

    onEditOk = (id, values) => {
        console.log(values);
        this.setState({editModalVisible: false}, () => {
            this.props.saveEntity(DataTypes.COMPOSITIONS, id, values, this.props.history);
        });
    }

    onEditCancel = () => {
        this.setState({editModalVisible: false});
    }

    render() {
        /*<Button type="primary"
                                icon={<EditOutlined/>}
                                onClick={this.showEditModal}>
                            Edytuj
                        </Button>
                        <Button type="primary"
                                icon={<PlusOutlined/>}
                                onClick={this.showAddModal}>
                            Dodaj
                        </Button>*/
        return <Row gutter={{xs: 4, sm: 8, md: 12, lg: 24}}>
            <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                <div style={{padding: '1em'}}>
                    <Input
                        placeholder="Szukaj po tytule"
                        value={this.state.fullText}
                        onChange={(e) => this.setState({fullText: e.target.value})}
                        onKeyDown={this.handleKeyDownFullText}
                        suffix={<CloseOutlined
                            onClick={this.handleResetFullText}/>}
                        addonAfter={<SearchOutlined
                            onClick={(e) => this.filterFullText(this.state.fullText)}/>}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={18} xl={18}>
                <div style={{padding: '1em'}}>
                    <Space>
                        <Button type="primary"
                                icon={<SearchOutlined/>}
                                onClick={this.showFilterModal}>
                            Wyszukaj
                        </Button>
                        <CompositionFilterModal
                            visible={this.state.filterModalVisible}
                            handleOk={this.onFilterOk}
                            handleCancel={this.onFilterCancel}
                            sources={this.props.sources}
                            categories={this.props.categories}
                        />
                        <Button type="primary"
                                icon={<ReloadOutlined/>}
                                onClick={this.refresh}
                                title="Odśwież">
                            Odśwież
                        </Button>
                        {amIAdmin() ?
                            <>
                                <Button type="primary"
                                        icon={<PlusOutlined/>}
                                        onClick={this.showAddModal}>
                                    Dodaj
                                </Button>
                                <Button type="primary"
                                        icon={<EditOutlined/>}
                                        onClick={this.showEditModal}
                                        disabled={!this.props.compositions_entity.id}
                                >
                                    Edytuj
                                </Button>
                            </> : null
                        }
                        <CompositionEditModal
                            visible={this.state.editModalVisible}
                            title={this.state.editModalTitle}
                            data={this.state.composition}
                            handleOk={this.onEditOk}
                            handleCancel={this.onEditCancel}
                            sources={this.props.sources}
                            categories={this.props.categories}
                        />
                    </Space>
                </div>
            </Col>
        </Row>
    }
}
