import React, {Component} from 'react';
import {Modal, Form, Input, Select, Button} from 'antd';
import {
    CloseOutlined,
    SearchOutlined
} from '@ant-design/icons';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

export class CompositionFilterModal extends Component {
    formRef = React.createRef();

    handleOk = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                //this.formRef.current.resetFields();
                this.props.handleOk(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    handleCancel = () => {
        //this.formRef.current.resetFields();
        this.props.handleCancel();
    }

    render() {
        //console.log(this.props.sources);
        return <Modal title="Wyszukiwanie utworów"
                      visible={this.props.visible}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                          <Button icon={<CloseOutlined/>} key="cancel" onClick={this.handleCancel}>
                              Anuluj
                          </Button>,
                          <Button icon={<SearchOutlined/>} key="search" type="primary" onClick={this.handleOk}>
                              Szukaj
                          </Button>,
                      ]}>
            <Form {...layout} ref={this.formRef} data={this.props.data}>
                <Form.Item name="title" label="Tytuł">
                    <Input/>
                </Form.Item>
                <Form.Item name="lyrics" label="Treść">
                    <Input/>
                </Form.Item>
                <Form.Item name="category" label="Kategoria">
                    <Select allowClear={true}
                            showSearch={true}
                            optionFilterProp={'label'}>
                        {
                            this.props.categories ?
                                this.props.categories.map((category) => <Select.Option key={category.id}
                                                                                       value={category.id}
                                                                                       label={category.name}>
                                    {category.name}
                                </Select.Option>) : null
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="lyrics_author" label="Autor tekstu">
                    <Input/>
                </Form.Item>
                <Form.Item name="composer" label="Kompozytor">
                    <Input/>
                </Form.Item>
                <Form.Item name="arranger" label="Opracowanie">
                    <Input/>
                </Form.Item>
                <Form.Item name="source" label="Źródło">
                    <Select allowClear={true}
                            showSearch={true}
                            optionFilterProp={'label'}>
                        {
                            this.props.sources ?
                                this.props.sources.map((source) => <Select.Option key={source.id}
                                                                                  value={source.id}
                                                                                  label={source.title}>
                                    {source.title}
                                </Select.Option>) : null
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    }
}
