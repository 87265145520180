import {createStore/*, combineReducers*/, applyMiddleware/*, compose*/} from "redux";
import {createBrowserHistory} from 'history';
import {/*routerReducer, */routerMiddleware} from 'react-router-redux';
import {MainReducer} from "./reducer";
import {asyncActions} from "./asyncMiddleware";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [asyncActions, routeMiddleware];

export const DataStore = createStore(MainReducer, applyMiddleware(...middlewares));
/*export const DataStore = createStore(
    combineReducers({
        SongbookReducer,
        router: routerReducer
    }),
    compose(applyMiddleware(...middlewares))
);*/
