export const triads = [
    {
        root: ['C', 4, 0],
        third: ['E', 4, 0],
        fifth: ['G', 4, 1],
        name: 'C zw.',
        type: 'zwiększony'
    },
    {
        root: ['C', 4, 0],
        third: ['E', 4, 0],
        fifth: ['G', 4, 0],
        name: 'C dur',
        type: 'durowy'
    },
    {
        root: ['C', 4, 0],
        third: ['E', 4, -1],
        fifth: ['G', 4, 0],
        name: 'c moll',
        type: 'molowy'
    },
    {
        root: ['C', 4, 0],
        third: ['E', 4, -1],
        fifth: ['G', 4, -1],
        name: 'c zm.',
        type: 'zmniejszony'
    },

    {
        root: ['D', 4, -1],
        third: ['F', 4, 0],
        fifth: ['A', 4, 0],
        name: 'Des zw.',
        type: 'zwiększony'
    },
    {
        root: ['D', 4, -1],
        third: ['F', 4, 0],
        fifth: ['A', 4, -1],
        name: 'Des dur',
        type: 'durowy'
    },
    {
        root: ['D', 4, -1],
        third: ['F', 4, -1],
        fifth: ['A', 4, -1],
        name: 'des moll',
        type: 'molowy'
    },
    {
        root: ['D', 4, -1],
        third: ['F', 4, -1],
        fifth: ['A', 4, -2],
        name: 'des zm.',
        type: 'zmniejszony'
    },

    {
        root: ['D', 4, 0],
        third: ['F', 4, 1],
        fifth: ['A', 4, 1],
        name: 'D zw.',
        type: 'zwiększony'
    },
    {
        root: ['D', 4, 0],
        third: ['F', 4, 1],
        fifth: ['A', 4, 0],
        name: 'D dur',
        type: 'durowy'
    },
    {
        root: ['D', 4, 0],
        third: ['F', 4, 0],
        fifth: ['A', 4, 0],
        name: 'd moll',
        type: 'molowy'
    },
    {
        root: ['D', 4, 0],
        third: ['F', 4, 0],
        fifth: ['A', 4, -1],
        name: 'd zm.',
        type: 'zmniejszony'
    },

    {
        root: ['E', 4, -1],
        third: ['G', 4, 0],
        fifth: ['B', 4, 0],
        name: 'Es zw.',
        type: 'zwiększony'
    },
    {
        root: ['E', 4, -1],
        third: ['G', 4, 0],
        fifth: ['B', 4, -1],
        name: 'Es dur',
        type: 'durowy'
    },
    {
        root: ['E', 4, -1],
        third: ['G', 4, -1],
        fifth: ['B', 4, -1],
        name: 'es moll',
        type: 'molowy'
    },
    {
        root: ['E', 4, -1],
        third: ['G', 4, -1],
        fifth: ['B', 4, -2],
        name: 'es zm.',
        type: 'zmniejszony'
    },

    {
        root: ['E', 4, 0],
        third: ['G', 4, 1],
        fifth: ['B', 4, 1],
        name: 'E zw.',
        type: 'zwiększony'
    },
    {
        root: ['E', 4, 0],
        third: ['G', 4, 1],
        fifth: ['B', 4, 0],
        name: 'E dur',
        type: 'durowy'
    },
    {
        root: ['E', 4, 0],
        third: ['G', 4, 0],
        fifth: ['B', 4, 0],
        name: 'e moll',
        type: 'molowy'
    },
    {
        root: ['E', 4, 0],
        third: ['G', 4, 0],
        fifth: ['B', 4, -1],
        name: 'e zm.',
        type: 'zmniejszony'
    },

    {
        root: ['F', 4, 0],
        third: ['A', 4, 0],
        fifth: ['C', 5, 1],
        name: 'F zw.',
        type: 'zwiększony'
    },
    {
        root: ['F', 4, 0],
        third: ['A', 4, 0],
        fifth: ['C', 5, 0],
        name: 'F dur',
        type: 'durowy'
    },
    {
        root: ['F', 4, 0],
        third: ['A', 4, -1],
        fifth: ['C', 5, 0],
        name: 'f moll',
        type: 'molowy'
    },
    {
        root: ['F', 4, 0],
        third: ['A', 4, -1],
        fifth: ['C', 5, -1],
        name: 'f zm.',
        type: 'zmniejszony'
    },

    {
        root: ['F', 4, 1],
        third: ['A', 4, 1],
        fifth: ['C', 5, 2],
        name: 'Fis zw.',
        type: 'zwiększony'
    },
    {
        root: ['F', 4, 1],
        third: ['A', 4, 1],
        fifth: ['C', 5, 1],
        name: 'Fis dur',
        type: 'durowy'
    },
    {
        root: ['F', 4, 1],
        third: ['A', 4, 0],
        fifth: ['C', 5, 1],
        name: 'fis moll',
        type: 'molowy'
    },
    {
        root: ['F', 4, 1],
        third: ['A', 4, 0],
        fifth: ['C', 5, 0],
        name: 'fis zm.',
        type: 'zmniejszony'
    },

    {
        root: ['G', 4, 0],
        third: ['B', 4, 0],
        fifth: ['D', 5, 1],
        name: 'G zw.',
        type: 'zwiększony'
    },
    {
        root: ['G', 4, 0],
        third: ['B', 4, 0],
        fifth: ['D', 5, 0],
        name: 'G dur',
        type: 'durowy'
    },
    {
        root: ['G', 4, 0],
        third: ['B', 4, -1],
        fifth: ['D', 5, 0],
        name: 'g moll',
        type: 'molowy'
    },
    {
        root: ['G', 4, 0],
        third: ['B', 4, -1],
        fifth: ['D', 5, -1],
        name: 'g zm.',
        type: 'zmniejszony'
    },

    {
        root: ['A', 4, -1],
        third: ['C', 5, 0],
        fifth: ['E', 5, 0],
        name: 'As zw.',
        type: 'zwiększony'
    },
    {
        root: ['A', 4, -1],
        third: ['C', 5, 0],
        fifth: ['E', 5, -1],
        name: 'As dur',
        type: 'durowy'
    },
    {
        root: ['A', 4, -1],
        third: ['C', 5, -1],
        fifth: ['E', 5, -1],
        name: 'as moll',
        type: 'molowy'
    },
    {
        root: ['A', 4, -1],
        third: ['C', 5, -1],
        fifth: ['E', 5, -2],
        name: 'as zm.',
        type: 'zmniejszony'
    },

    {
        root: ['A', 4, 0],
        third: ['C', 5, 1],
        fifth: ['E', 5, 1],
        name: 'A zw.',
        type: 'zwiększony'
    },
    {
        root: ['A', 4, 0],
        third: ['C', 5, 1],
        fifth: ['E', 5, 0],
        name: 'A dur',
        type: 'durowy'
    },
    {
        root: ['A', 4, 0],
        third: ['C', 5, 0],
        fifth: ['E', 5, 0],
        name: 'a moll',
        type: 'molowy'
    },
    {
        root: ['A', 4, 0],
        third: ['C', 5, 0],
        fifth: ['E', 5, -1],
        name: 'a zm.',
        type: 'zmniejszony'
    },

    {
        root: ['B', 4, -1],
        third: ['D', 5, 0],
        fifth: ['F', 5, 1],
        name: 'B zw.',
        type: 'zwiększony'
    },
    {
        root: ['B', 4, -1],
        third: ['D', 5, 0],
        fifth: ['F', 5, 0],
        name: 'B dur',
        type: 'durowy'
    },
    {
        root: ['B', 4, -1],
        third: ['D', 5, -1],
        fifth: ['F', 5, 0],
        name: 'b moll',
        type: 'molowy'
    },
    {
        root: ['B', 4, -1],
        third: ['D', 5, -1],
        fifth: ['F', 5, -1],
        name: 'b zm.',
        type: 'zmniejszony'
    },

    {
        root: ['B', 4, 0],
        third: ['D', 5, 1],
        fifth: ['F', 5, 2],
        name: 'H zw.',
        type: 'zwiększony'
    },
    {
        root: ['B', 4, 0],
        third: ['D', 5, 1],
        fifth: ['F', 5, 1],
        name: 'H dur',
        type: 'durowy'
    },
    {
        root: ['B', 4, 0],
        third: ['D', 5, 0],
        fifth: ['F', 5, 1],
        name: 'h moll',
        type: 'molowy'
    },
    {
        root: ['B', 4, 0],
        third: ['D', 5, 0],
        fifth: ['F', 5, 0],
        name: 'h zm.',
        type: 'zmniejszony'
    }
];