import {getSoundName, soundToPitches} from "./helper";

export class MusicXml {
    static getClef = (item = {sign: 'G', line: 2}) => {
        return `<clef>
            <sign>${item.sign}</sign>
            <line>${item.line}</line>
        </clef>`
    }

    static getClefByNote = (item) => {
        if (soundToPitches(item) < 59) {
            return `<clef>
            <sign>F</sign>
            <line>4</line>
        </clef>`
        }
        return `<clef>
            <sign>G</sign>
            <line>2</line>
        </clef>`
    }

    static getEndBarline = () => `<barline location="right">
        <bar-style>light-heavy</bar-style>
        </barline>`;

    static getPianoPart = () => `<part-list>
            <score-part id="P1">
            <part-name>Piano</part-name>
        <part-abbreviation>Pno.</part-abbreviation>
        <score-instrument id="P1-I1">
            <instrument-name>Piano</instrument-name>
        </score-instrument>
        <midi-device id="P1-I1" port="1"></midi-device>
        <midi-instrument id="P1-I1">
            <midi-channel>1</midi-channel>
            <midi-program>1</midi-program>
            <volume>78.7402</volume>
            <pan>0</pan>
        </midi-instrument>
    </score-part>
    </part-list>`

    static getPitch = (item) => `<pitch>
                        <step>${item[0]}</step>
                        <octave>${item[1]}</octave>
                        <alter>${item[2]}</alter>
                    </pitch>`;

    static buildClefMusicXML = (item) => {
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
        <part id="P1">
            <measure number="1" width="318.69">
                <attributes>
                    <divisions>1</divisions>
                    <key>
                        <fifths>0</fifths>
                    </key>
                    <time>
                        <beats>4</beats>
                        <beat-type>4</beat-type>
                    </time>
                    ${MusicXml.getClef(item)}
                </attributes>
                <note>
                    <pitch>
                        <step>${item.sign}</step>
                        <octave>${item.octave}</octave>
                        <alter>0</alter>
                    </pitch>
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>whole</type>
                </note>
                ${MusicXml.getEndBarline()}
            </measure>
        </part>
    </score-partwise>`;
    }

    static buildNoteMusicXML = (item) => {
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
        <part id="P1">
            <measure number="1" width="318.69">
                <attributes>
                    <divisions>1</divisions>
                    <key>
                        <fifths>0</fifths>
                    </key>
                    <time>
                        <beats>4</beats>
                        <beat-type>4</beat-type>
                    </time>
                    ${MusicXml.getClefByNote(item)}
                </attributes>
                <note>
                    ${MusicXml.getPitch(item)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>whole</type>
                </note>
                ${MusicXml.getEndBarline()}
            </measure>
        </part>
    </score-partwise>`;
    }

    static buildNoteValueMusicXML = (item) => {
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
        <part id="P1">
            <measure number="1" width="400">
                <attributes>
                    <divisions>16</divisions>
                    <key>
                        <fifths>0</fifths>
                    </key>
                    ${MusicXml.getClef()}
                </attributes>
                <note>
                ${item.rest ?
            `<rest/>` :
            MusicXml.getPitch(['C', 4, 0])
        }
                    <duration>${item.value * 64}</duration>
                    <voice>1</voice>
                    <type>${item.type}</type>
                </note>
                ${MusicXml.getEndBarline()}
            </measure>
        </part>
    </score-partwise>`;
    }


    static buildIntervalMusicXML = (item, harmonic = false) => {
        const type = harmonic ? 'whole' : 'half';
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
        <part id="P1">
            <measure number="1" width="318.69">
                <attributes>
                    <divisions>1</divisions>
                    <key>
                        <fifths>0</fifths>
                    </key>
                    <time>
                        <beats>4</beats>
                        <beat-type>4</beat-type>
                    </time>
                    ${MusicXml.getClef()}
                </attributes>
                <note>
                    ${MusicXml.getPitch(item.from)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.to)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                ${MusicXml.getEndBarline()}
            </measure>
        </part>
    </score-partwise>`;
    }

    static buildIntervalInversionsMusicXML = (item) => {
        return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
<score-partwise version="3.1">
${MusicXml.getPianoPart()}
  <part id="P1">
    <measure number="1" width="229.08">
      <attributes>
        <divisions>1</divisions>
        <key>
          <fifths>0</fifths>
          </key>
        <time>
          <beats>4</beats>
          <beat-type>4</beat-type>
          </time>
        ${MusicXml.getClef()}
        </attributes>
      <note default-x="77.22" default-y="-50.00">
        ${MusicXml.getPitch(item.from)}
        <duration>4</duration>
        <voice>1</voice>
        <type>whole</type>
        </note>
      <note default-x="77.22" default-y="-35.00">
        <chord/>
        ${MusicXml.getPitch(item.to)}
        <duration>4</duration>
        <voice>1</voice>
        <type>whole</type>
        </note>
      </measure>
    <measure number="2" width="173.71">
      <note default-x="13.00" default-y="-35.00">
        ${MusicXml.getPitch(item.from)}
        <duration>4</duration>
        <voice>1</voice>
        <type>whole</type>
        </note>
      <note default-x="13.00" default-y="-15.00">
        <chord/>
        ${MusicXml.getPitch([item.to[0], item.to[1] - 1, item.to[2]])}
        <duration>4</duration>
        <voice>1</voice>
        <type>whole</type>
        </note>
      ${MusicXml.getEndBarline()}
      </measure>
    </part>
  </score-partwise>
`;
    }

    static buildTriadMusicXML = (item, harmonic = false) => {
        const type = harmonic ? 'whole' : 'quarter';
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
        <part id="P1">
            <measure number="1" width="318.69">
                <attributes>
                    <divisions>1</divisions>
                    <key>
                        <fifths>0</fifths>
                    </key>
                    <time>
                        <beats>4</beats>
                        <beat-type>4</beat-type>
                    </time>
                    ${MusicXml.getClef()}
                </attributes>
                <note>
                    ${MusicXml.getPitch(item.root)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.third)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.fifth)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                ${MusicXml.getEndBarline()}
            </measure>
        </part>
    </score-partwise>`;
    }

    static buildTriadInversionsMusicXML = (item, harmonic = true) => {
        const type = harmonic ? 'whole' : 'quarter';
        return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
<score-partwise version="3.1">
${MusicXml.getPianoPart()}
  <part id="P1">
    <measure number="1" width="229.08">
      <attributes>
        <divisions>1</divisions>
        <key>
          <fifths>0</fifths>
          </key>
        <time>
          <beats>4</beats>
          <beat-type>4</beat-type>
          </time>
        ${MusicXml.getClef()}
        </attributes>
      <note>
                    ${MusicXml.getPitch(item.root)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
      <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.third)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.fifth)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
      </measure>
    <measure number="2" width="173.71">
      <note>
      ${MusicXml.getPitch([item.root[0], item.root[1] + 1, item.root[2]])}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
      <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.third)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.fifth)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
      </measure>
      <measure number="3" width="173.71">
      <note>
                    ${MusicXml.getPitch([item.root[0], item.root[1] + 1, item.root[2]])}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch([item.third[0], item.third[1] + 1, item.third[2]])}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
                <note>
                ${harmonic ? `<chord/>` : ''}
                    ${MusicXml.getPitch(item.fifth)}
                    <duration>2</duration>
                    <voice>1</voice>
                    <type>${type}</type>
                </note>
      ${MusicXml.getEndBarline()}
      </measure>
    </part>
  </score-partwise>
`;
    }

    static buildKeyMusicXML = (item) => {
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
        <part id="P1">
            <measure number="1" width="318.69">
                <attributes>
                    <divisions>1</divisions>
                    <key>
                        <fifths>${item.fifths}</fifths>
                    </key>
                    <time>
                        <beats>4</beats>
                        <beat-type>4</beat-type>
                    </time>
                    ${MusicXml.getClef()}
                </attributes>
                ${MusicXml.getEndBarline()}
            </measure>
        </part>
    </score-partwise>`;
    }


    static buildScaleMusicXML = (item, withKey = false) => {
        const notes = item.notes.map(note => `<note>
    ${MusicXml.getPitch(note)}
    <duration>2</duration>
    <voice>1</voice>
    <type>quarter</type>
    <lyric justify="center" name="verse" number="1" placement="below">
         <syllabic>single</syllabic>
         <text>${getSoundName(note, false)}</text>
      </lyric>
    </note>`);
        return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.1 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
        <score-partwise version="3.1">
            ${MusicXml.getPianoPart()}
            <part id="P1">
                <measure number="1" width="318.69">
                    <attributes>
                        <divisions>1</divisions>
                        <key>
                            <fifths>${withKey ? item.fifths : 0}</fifths>
                        </key>
                        ${MusicXml.getClef()}
                    </attributes>
                    ${notes[0] + notes[1] + notes[2] + notes[3]}
                </measure>
                <measure number="2" width="318.69">
                    ${notes[4] + notes[5] + notes[6] + notes[7]}
                    ${MusicXml.getEndBarline()}
                </measure>
            </part>
        </score-partwise>`;
    }
}