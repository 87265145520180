import React from 'react';
import {Route} from 'react-router-dom';
import {Layout} from "antd";

export const LoginLayout = ({children}) => (
    <Layout style={{backgroundColor: '#eee', height: '100%', overflow: 'auto'}}>
        <div style={{margin: '0 auto', padding: '1em'}}>
            <div style={{
                textAlign: 'center'
            }}>
                <img alt="logo" src="/img/logo.svg"/>
            </div>
            {children}
        </div>
        <div style={{textAlign: 'center', background: 'none'}}>Pedro Software & Art ©2019</div>
    </Layout>
);

const LoginLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <LoginLayout>
                <Component {...matchProps} />
            </LoginLayout>
        )}/>
    )
};

export default LoginLayoutRoute;
