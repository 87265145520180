import {soundToPitches} from "./helper";

export class MidiPlayer {

    static playNote(midiSounds, item) {
        let when = midiSounds.contextTime();
        const s = soundToPitches(item);
        midiSounds.playChordAt(when, 3, [s], 4);
    }

    static playInterval(midiSounds, item, harmonic = false) {
        let when = midiSounds.contextTime();
        const s = [soundToPitches(item.from), soundToPitches(item.to)];
        const d = 1;
        if (harmonic) {
            midiSounds.playChordAt(when, 3, s, d);
        } else {
            midiSounds.playChordAt(when, 3, [s[0]], d);
            midiSounds.playChordAt(when + d, 3, [s[1]], d);
        }
    }

    static playIntervalInversion(midiSounds, item) {
        let when = midiSounds.contextTime();
        const s1 = [soundToPitches(item.from), soundToPitches(item.to)];
        const s2 = [soundToPitches(item.from), soundToPitches([item.to[0], item.to[1] - 1, item.to[2]])];
        const d = 4
        midiSounds.playChordAt(when, 3, s1, d);
        midiSounds.playChordAt(when + d, 3, s2, d);
    }

    static playTriad(midiSounds, item) {
        let when = midiSounds.contextTime();
        const s = [soundToPitches(item.root), soundToPitches(item.third), soundToPitches(item.fifth)];
        midiSounds.playChordAt(when, 3, s, 1);
    }

    static playTriadInversion(midiSounds, item) {
        let when = midiSounds.contextTime();
        const s = [soundToPitches(item.root), soundToPitches(item.third), soundToPitches(item.fifth)];
        const s1 = [soundToPitches([item.root[0], item.root[1] + 1, item.root[2]]), soundToPitches(item.third), soundToPitches(item.fifth)];
        const s2 = [soundToPitches([item.root[0], item.root[1] + 1, item.root[2]]), soundToPitches([item.third[0], item.third[1] + 1, item.third[2]]), soundToPitches(item.fifth)];
        const d = 4;
        midiSounds.playChordAt(when, 3, s, d);
        midiSounds.playChordAt(when + d, 3, s1, d);
        midiSounds.playChordAt(when + 2 * d, 3, s2, d);
    }

    static playScale(midiSounds, item) {
        let when = midiSounds.contextTime();
        const d = 1;
        item.notes.forEach((note, i) => {
            const s = [soundToPitches(note)];
            midiSounds.playChordAt(when + d * i, 3, s, d);
        });
    }
}