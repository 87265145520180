export const notes = [
    ['C', 2, -1],
    ['C', 2, 0],
    ['C', 2, 1],
    ['D', 2, -1],
    ['D', 2, 0],
    ['D', 2, 1],
    ['E', 2, -1],
    ['E', 2, 0],
    ['E', 2, 1],
    ['F', 2, -1],
    ['F', 2, 0],
    ['F', 2, 1],
    ['G', 2, -1],
    ['G', 2, 0],
    ['G', 2, 1],
    ['A', 2, -1],
    ['A', 2, 0],
    ['A', 2, 1],
    ['B', 2, -1],
    ['B', 2, 0],
    ['B', 2, 1],
    ['C', 3, -1],
    ['C', 3, 0],
    ['C', 3, 1],
    ['D', 3, -1],
    ['D', 3, 0],
    ['D', 3, 1],
    ['E', 3, -1],
    ['E', 3, 0],
    ['E', 3, 1],
    ['F', 3, -1],
    ['F', 3, 0],
    ['F', 3, 1],
    ['G', 3, -1],
    ['G', 3, 0],
    ['G', 3, 1],
    ['A', 3, -1],
    ['A', 3, 0],
    ['A', 3, 1],
    ['B', 3, -1],
    ['B', 3, 0],
    ['B', 3, 1],
    ['C', 4, -1],
    ['C', 4, 0],
    ['C', 4, 1],
    ['C', 4, 2],
    ['D', 4, -2],
    ['D', 4, -1],
    ['D', 4, 0],
    ['D', 4, 1],
    ['E', 4, -1],
    ['E', 4, 0],
    ['E', 4, 1],
    ['F', 4, -1],
    ['F', 4, 0],
    ['F', 4, 1],
    ['G', 4, -1],
    ['G', 4, 0],
    ['G', 4, 1],
    ['A', 4, -1],
    ['A', 4, 0],
    ['A', 4, 1],
    ['B', 4, -1],
    ['B', 4, 0],
    ['B', 4, 1],
    ['C', 5, -1],
    ['C', 5, 0],
    ['C', 5, 1],
    ['D', 5, -1],
    ['D', 5, 0],
    ['D', 5, 1],
    ['E', 5, -1],
    ['E', 5, 0],
    ['E', 5, 1],
    ['F', 5, -1],
    ['F', 5, 0],
    ['F', 5, 1],
    ['G', 5, -1],
    ['G', 5, 0],
    ['G', 5, 1],
    ['A', 5, -1],
    ['A', 5, 0],
    ['A', 5, 1],
    ['B', 5, -1],
    ['B', 5, 0],
    ['B', 5, 1],
    ['C', 6, -1],
    ['C', 6, 0],
    ['C', 6, 1],
];