import React, {Component} from 'react';

export class CompositionLyrics extends Component {
    prepareLyrics = (lyrics) => {
        return lyrics ? lyrics.split('\n').map((row, key) => {
            let text = row.replace('{', '<span class="lyrics-prefix">')
                .replace('}', '</span>')
                .replace('[', '<span class="lyrics-chords">')
                .replace(']', '</span>');
            return <React.Fragment key={key}>
                <span dangerouslySetInnerHTML={{__html: text}}></span>
                <br/>
            </React.Fragment>
        }) : '';
        //return lyrics.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

    render() {
        return <div style={{marginTop: '1em'}}>
            {this.prepareLyrics(this.props.lyrics)}
        </div>
    }
}
