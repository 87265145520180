import React from 'react';
import {Worker, Viewer} from '@react-pdf-viewer/core';
import {toolbarPlugin} from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import {Button} from "antd";

export const PdfView = (props) => {

    const toolbarPluginInstance = toolbarPlugin();
    const {Toolbar} = toolbarPluginInstance;

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <div
                    style={{
                        alignItems: 'center',
                        backgroundColor: 'rgb(250, 250, 250)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        padding: '4px',
                    }}
                >
                    <Toolbar/>
                </div>
                <div
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                    }}
                >
                    <Viewer
                        fileUrl={props.file}
                        plugins={[
                            toolbarPluginInstance,
                        ]}
                    />
                </div>

            <Button type="primary" key="download" onClick={props.download}>
                Pobierz
            </Button>
        </Worker>
    );
}
