import React, {Component} from 'react';
import {Modal, Form, Input, Select, Button, Upload, message} from 'antd';
import {
    CloseOutlined,
    SaveOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {urls} from "../../data/api/urls";
import {RestDataSource} from "../../data/api/restDataSource";
import {FILE_TYPE_UNKNOWN, getFileTypeIdByMimeType, getPartByName} from "../../data/fileTypes";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

export class CompositionFileModal extends Component {

    state = {
        fileTypeId: FILE_TYPE_UNKNOWN,
        fileName: ''
    };

    formRef = React.createRef();

    handleOk = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                values.file_name = this.state.fileName;
                values.file_type_id = this.state.fileTypeId;
                this.formRef.current.resetFields();
                this.props.handleOk(values);
                this.setState({fileTypeId: FILE_TYPE_UNKNOWN, fileName: ''});
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    handleCancel = () => {
        this.formRef.current.resetFields();
        this.setState({fileTypeId: FILE_TYPE_UNKNOWN, fileName: ''});
        this.props.handleCancel();
    }

    beforeUpload = file => {
        //console.log('beforeUpload', file);
        const fileTypeId = getFileTypeIdByMimeType(file.type);
        if (fileTypeId === FILE_TYPE_UNKNOWN) {
            message.error(`Nierozpoznany format pliku ${file.name}.`);
        }
        return fileTypeId !== FILE_TYPE_UNKNOWN;
    }

    onUploadChange = (info) => {
        /*if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }*/
        if (info.file.status === 'done') {
            console.log(info.file.response.uploaded);
            const fileTypeId = getFileTypeIdByMimeType(info.file.response.uploaded.type);
            const part = getPartByName(info.file.response.uploaded.name);
            this.formRef.current.setFieldsValue({part_id: part.id, description: part.name});
            this.setState({fileTypeId, fileName: info.file.response.uploaded.name});
            message.success(`Plik ${info.file.name} wysłany poprawnie`);
        } else if (info.file.status === 'error') {
            message.error(`Błąd wysyłania pliku ${info.file.name}.`);
        }
    }

    render() {
        const uploadProps = {
            name: 'file',
            action: urls.upload_file,
            headers: RestDataSource.getAuthorizationHeader(),
            showUploadList: false,
            data: {},
            beforeUpload: this.beforeUpload,
            onChange: this.onUploadChange,
        };
        //console.log(this.props.data)
        return <Modal title={this.props.title}
                      visible={this.props.visible}
                      width={700}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                          <Button icon={<CloseOutlined/>} key="cancel" onClick={this.handleCancel}>
                              Anuluj
                          </Button>,
                          <Button
                              icon={<SaveOutlined/>}
                              key="save"
                              type="primary"
                              onClick={this.handleOk}
                              disabled={this.state.fileTypeId === FILE_TYPE_UNKNOWN}>
                              Zapisz
                          </Button>
                      ]}>
            <Form {...layout}
                  ref={this.formRef}
                  fields={Object.keys(this.props.data).map(key => ({
                      name: key,
                      value: this.props.data[key]
                  }))
                  }
            >
                <Form.Item
                    label="Plik"
                    rules={[
                        {
                            required: true,
                            message: 'Wybierz plik'
                        }
                    ]}
                >
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined/>}>Załaduj</Button>
                    </Upload>
                    {this.state.fileName && <p style={{fontSize: '0.8em', marginBottom: 0}}>{this.state.fileName}</p>}
                </Form.Item>

                <Form.Item
                    name="part_id"
                    label="Nazwa"
                    rules={[
                        {
                            required: true,
                            message: 'Wybierz nazwę'
                        }
                    ]}>
                    <Select allowClear={true}
                            showSearch={true}
                            optionFilterProp={'label'}
                            disabled={this.state.fileTypeId === FILE_TYPE_UNKNOWN}
                    >
                        {
                            this.props.parts ?
                                this.props.parts.map((part) => <Select.Option key={part.id}
                                                                              value={parseInt(part.id)}
                                                                              label={part.name}>
                                    {part.name}
                                </Select.Option>) : null
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Opis"
                >
                    <Input disabled={this.state.fileTypeId === FILE_TYPE_UNKNOWN}/>
                </Form.Item>

            </Form>
        </Modal>
    }
}
