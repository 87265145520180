import React, {Component} from 'react';
import {Empty, Row, Col, Card, Tooltip, Space, Button} from 'antd';
import {fileTypes} from "../../data/fileTypes";
import {PdfView} from "./PdfView";
import {Audio} from "./Audio";
import {MsczView} from "./MsczView";
import {PlusOutlined, DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import {CompositionFileModal} from "./CompositionFileModal";
import {amIAdmin} from "../../helpers/auth";
import {Popconfirm} from 'antd';

export class CompositionFiles extends Component {

    state = {
        fileModalVisible: false,
        fileModalTitle: 'Dodaj plik',
        currentFile: {}
    }

    prepareFiles = (files) => {
        return <Row gutter={16}>
            {
                files.map((file, key) => {
                    //xs={24} sm={12} md={12} lg={8} xl={6}
                    /*actions={[
                                  <Tooltip title="Otwórz plik" key="open">
                                      <FolderOpenOutlined onClick={() => this.props.openFile(file)}/>
                                  </Tooltip>,
                                  <Tooltip title="Pobierz plik" key="download">
                                      <DownloadOutlined onClick={() => this.props.downloadFile(file)}/>
                                  </Tooltip>,
                                  <Tooltip title="Usuń plik" key="delete">
                                      <DeleteOutlined onClick={() => this.props.deleteFile(file)}/>
                                  </Tooltip>,
                              ]}*/
                    return <Col xs={24} sm={12} md={12} lg={24} key={key}
                                style={{textAlign: 'center', marginBottom: '0.5em'}}>
                        <Tooltip
                            title={fileTypes[file.file_type_id] ? fileTypes[file.file_type_id].type : fileTypes['0'].type}>
                            <Card hoverable={true}
                                  onClick={() => this.props.openFile(file)}
                                  bodyStyle={{
                                      padding: '0.5em',
                                      backgroundColor: this.props.file && file.id === this.props.file.id ? '#e6f7ff' : '#fff'
                                  }}
                            >
                                <span style={{fontSize: '30px'}}>
                                    {fileTypes[file.file_type_id] ? fileTypes[file.file_type_id].icon : fileTypes['0'].icon}
                                </span>
                                <p style={{margin: '0'}}>
                                    {file.part_name}
                                </p>
                            </Card>
                        </Tooltip>
                    </Col>
                })
            }
        </Row>;
    }

    renderFile = (file) => {
        switch (file.file_type_id) {
            case '1':
                return <PdfView file={file.fileURL} download={() => this.props.downloadFile(file)}/>;
            case '2':
                return <MsczView file={file.data} download={() => this.props.downloadFile(file)}/>;
            case '3':
                return <Audio file={file.fileURL} download={() => this.props.downloadFile(file)}/>;
            default:
                return null;
        }
    }

    showFileModal = () => {
        this.setState({fileModalVisible: true, fileModalTitle: 'Dodaj plik'});
    }

    onFileOk = (data) => {
        data.composition_id = this.props.composition.id;
        //console.log(data);
        this.props.addFile(data);
        this.setState({fileModalVisible: false});
    }

    onFileCancel = () => {
        this.setState({fileModalVisible: false});
    }

    onDeleteFile = () => {
        if (this.props.file) {
            //console.log(this.props.file);
            this.props.deleteFile(this.props.file);
        }
    }

    handleDownload = () => {
        if (this.props.file) {
            this.props.downloadFile(this.props.file);
        }
    }

    render() {
        //console.log(this.props.file);
        const {file} = this.props;
        return <div style={{margin: '1em'}}>
            {amIAdmin() ? <div style={{paddingBottom: '1em'}}>
                <Space>
                    <Button type="primary"
                            icon={<PlusOutlined/>}
                            onClick={this.showFileModal}
                            title={'Dodaj plik'}>
                        Dodaj plik
                    </Button>
                    <CompositionFileModal
                        visible={this.state.fileModalVisible}
                        title={this.state.fileModalTitle}
                        handleOk={this.onFileOk}
                        handleCancel={this.onFileCancel}
                        parts={this.props.parts}
                        data={this.state.currentFile}
                    />
                    <Popconfirm
                        title="Czy na pewno usunąć plik?"
                        onConfirm={this.onDeleteFile}
                        okText="Tak"
                        cancelText="Nie"
                    >
                        <Button type="primary"
                                danger
                                icon={<DeleteOutlined/>}
                                title={'Usuń plik'}
                                disabled={!this.props.file}>
                            Usuń plik
                        </Button>
                    </Popconfirm>
                    <Button
                        icon={<SaveOutlined/>}
                        key="download"
                        type="primary"
                        onClick={this.handleDownload}
                        disabled={!this.props.file}>
                        Pobierz
                    </Button>
                </Space>
            </div> : null}
            {
                this.props.files.length ?
                    <Row gutter={8}>
                        <Col xs={24} lg={20}>
                            <div style={{marginBottom: '1.5em'}}>
                                <div
                                    style={{
                                        border: '1px solid #f0f0f0',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {
                                        file ?
                                            this.renderFile(file) :
                                            <Empty
                                                description={'Wybierz plik do otwarcia'}
                                                style={{margin: '1.5em'}}
                                            />
                                    }
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} lg={4}>
                            {this.prepareFiles(this.props.files)}
                        </Col>
                    </Row>
                    : <Empty description={'Brak plików'}/>
            }
        </div>
    }
}
