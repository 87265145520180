import React, {Component} from "react";
import {Space, Table, Tooltip} from "antd";
import {
    YoutubeOutlined as Youtube,
    FileOutlined as File
} from '@ant-design/icons';

export class CompositionList extends Component {
    onRow = (record) => {
        return {
            onClick: () => {
                this.props.onSelect(record)
            }
        };
    }

    setRowClassName = (record) => {
        return record.id === this.props.current.id ? 'selectable ant-table-row-selected' : 'selectable';
    }

    render() {
        const columns = [
            {
                title: 'Tytuł',
                dataIndex: 'title',
                key: 'title',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (text, record) => {
                    return <React.Fragment>
                        <Space>
                        <span>
                            {record.title}
                        </span>
                            {record.link_count ? <Tooltip title="Linki do Youtube">
                                <Youtube style={{color: "#cd201f"}}/>
                            </Tooltip> : ''}
                            {record.file_count ? <Tooltip title="Pliki z nutami">
                                <File style={{color: "#3366ff"}}/>
                            </Tooltip> : ''}
                        </Space>
                        {record.subtitle ? <p style={{fontSize: '0.8em'}}>{record.subtitle}</p> : ''}
                    </React.Fragment>
                }
            },
        ];

        return <Table
            size="small"
            columns={columns}
            dataSource={this.props.data}
            onRow={this.onRow}
            rowKey={'id'}
            rowClassName={this.setRowClassName}
            pagination={{
                total: this.props.total,
                current: this.props.params ? this.props.params.page : 1,
                pageSize: this.props.params ? this.props.params.limit : 10,
                pageSizeOptions: ['10', '20'],
                showSizeChanger: false,
                showQuickJumper: false,
                //showTotal: this.showTotalItemsDialog,
            }}
            onChange={this.props.onChangeList}
        />
    }
}
