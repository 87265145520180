import React, {Component} from 'react';
import {Empty, Layout, Typography} from "antd";
import moment from "moment";
import {CompositionLyrics} from "../songbook/CompositionLyrics";

const {Content} = Layout;

export default class LyricsContainer extends Component {

    state = {
        event: {}
    }

    componentDidMount() {
        this.props.loadEvent(this.props.match.params.id, this.props.history, (event) => {
            this.setState({event: event});
        });
    }

    render() {
        if (this.state.event && ('id' in this.state.event)) {
            return <Content>
                <div style={{textAlign: 'center'}}>
                    <Typography.Title level={4} style={{textTransform: 'uppercase', margin: '0'}}>
                        {this.state.event.type_name}
                    </Typography.Title>
                    <Typography.Title level={5} style={{margin: '0'}}>
                        {this.state.event.start ? moment(this.state.event.start).format('D MMMM YYYY H:mm') : ''}
                    </Typography.Title>
                    <p style={{fontStyle: 'italic'}}>{this.state.event.description}</p>
                </div>

                {this.state.event.compositions.length > 0 ?
                    <div style={{textAlign: 'left'}}>
                        <div style={{borderTop: '1px solid #999'}}/>
                        {this.state.event.compositions.map((item, key) => {
                            return <React.Fragment key={key}>
                                <div style={{
                                    fontSize: '0.9em',
                                    textTransform: 'uppercase',
                                    padding: '6px 0'
                                }}>{item.event_part_name}</div>
                                <Typography.Title level={2}>{item.composition_title}</Typography.Title>
                                <CompositionLyrics lyrics={item.composition_lyrics}>
                                </CompositionLyrics>
                                <div style={{borderTop: '1px solid #999'}}></div>
                            </React.Fragment>
                        })}
                    </div>
                    : <Empty description={"Brak repertuaru"}/>
                }

            </Content>
        }
        return <Empty description={"Brak wydarzenia"}/>
    }
}
