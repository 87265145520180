import React, {Component} from 'react';
import {Button, Layout, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import MIDISounds from 'midi-sounds-react';
import {CaretRightOutlined} from '@ant-design/icons';
import {intervals} from './intervals';
import {getInversion} from "./helper";
import {MidiPlayer} from "./MidiPlayer";
import {MusicXml} from "./MusicXml";

const {Content} = Layout;

export default class IntervalInversionsContainer extends Component {

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Przewroty interwałów
                    </Typography.Title>

                    <div style={{display: 'none'}}>
                        <MIDISounds
                            ref={(ref) => (this.midiSounds = ref)}
                            appElementName="root" instruments={[3]}
                        />
                    </div>

                    <p style={{marginTop: '16px'}}>
                        Przewrót interwału to przeniesienie jednego ze składników o oktawę w dół lub w górę; nowe
                        współbrzmienie dopełnia się z pierwszym do oktawy, z czego wynika, że interwały małe w
                        przewrocie dają interwały wielkie, zmniejszone - zwiększone (i odwrotnie).
                        Przewrót interwału czystego daje również interwał czysty.
                    </p>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {intervals.map((item, key) => {
                            return <div key={key} style={{width: '400px', textAlign: 'center'}}>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildIntervalInversionsMusicXML(item)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    stretchLastSystemLine={true}
                                />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <Button icon={<CaretRightOutlined/>}
                                            onClick={() => MidiPlayer.playIntervalInversion(this.midiSounds, item)}></Button>
                                    <div>{item.name} - {getInversion(item)}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
