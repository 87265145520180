import React, {Component} from 'react';
import {Empty, Layout, Typography, Button, Divider, Tag} from "antd";
import moment from "moment";
import {FilePdfOutlined, YoutubeOutlined} from '@ant-design/icons';
import {fileTypes} from "../../data/fileTypes";

const {Content} = Layout;

export default class EventContainer extends Component {

    state = {
        event: {}
    }

    componentDidMount() {
        const part = this.props.match.params.part ? this.props.match.params.part : 10;
        this.props.loadEvent(this.props.match.params.id, part, this.props.history, (event) => {
            this.setState({event: event});
        });
    }

    printRepertoire = () => {
        const part = this.props.match.params.part ? this.props.match.params.part : 10;
        this.props.printRepertoire(this.state.event.id, part, this.props.history);
    }

    downloadFile = (file) => {
        this.props.downloadFile(file, this.props.history);
    }

    goToLyrics = () => {
        this.props.history.push('/l/' + this.state.event.id);
    }

    openYoutube = (link) => {
        window.open(link.link, '_blank');
    }

    render() {
        if (this.state.event && ('id' in this.state.event)) {
            return <Content>
                <div style={{textAlign: 'center', paddingBottom: '16px'}}>
                    <Typography.Title level={4} style={{textTransform: 'uppercase', margin: '0'}}>
                        {this.state.event.type_name}
                    </Typography.Title>
                    <Typography.Title level={5} style={{margin: '0'}}>
                        {this.state.event.start ? moment(this.state.event.start).format('D MMMM YYYY H:mm') : ''}
                    </Typography.Title>
                    <p style={{fontStyle: 'italic'}}>{this.state.event.description}</p>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        {this.state.event.part_name}
                    </Typography.Title>
                </div>

                {this.state.event.compositions.length > 0 ?
                    <div style={{textAlign: 'left'}}>
                        {this.state.event.compositions.map((item, key) => {
                            return <React.Fragment key={key}>
                                <div style={{display: 'flex', gap: '4px', alignItems: 'center', padding: '5px'}}>
                                    {item.composition_title ?
                                        <h3 style={{
                                            fontSize: '18px',
                                            marginBottom: 0,
                                            lineHeight: 1
                                        }}>{item.composition_title}</h3> :
                                        null
                                    }
                                    <div><Tag>{item.event_part_name}</Tag></div>
                                </div>
                                {item.info ? <p style={{padding: '5px'}}>{item.info}</p> : null}
                                {item.files && <div style={{padding: '16px'}}>
                                    {item.files.map((file, key) => {
                                        return <Button
                                            key={key}
                                            icon={fileTypes[file.file_type_id].icon}
                                            onClick={() => this.downloadFile(file)}
                                        >
                                            {file.part_name}
                                        </Button>
                                    })}</div>}

                                {item.links && <div style={{padding: '16px'}}>
                                    <h4>Youtube:</h4>
                                        {item.links.map((link, key) => {
                                            return <Button
                                                key={key}
                                                icon={<YoutubeOutlined style={{color: 'red'}}/>}
                                                onClick={() => this.openYoutube(link)}
                                                style={{maxWidth: '100%', overflow: 'hidden'}}
                                            >
                                                {link.link_title}
                                            </Button>
                                        })}
                                </div>}
                                <Divider/>

                            </React.Fragment>
                        })}
                        <div style={{marginTop: '1em'}}>
                            <Button
                                danger
                                icon={<FilePdfOutlined/>}
                                onClick={this.printRepertoire}
                            >
                                Pobierz materiały
                            </Button>
                        </div>
                    </div>
                    : <Empty description={"Brak repertuaru"}/>
                }

            </Content>
        }
        return <Empty description={"Brak wydarzenia"}/>
    }
}
/*<Button onClick={this.goToLyrics}>Wyświetl teksty</Button>*/