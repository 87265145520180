import axios from "axios";
import {urls} from "./urls";

export class RestDataSource {
    sendRequest = (method, url, params = null, data = null, headers = null, responseType = 'json') => {
        return axios.request({method, url, headers, params, data, responseType});
    }

    static getAuthorizationHeader = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const header = {
            'Authorization': `Bearer ${user.access_token}`
        }
        return header;
    }

    getCollection = (dataType, params) => {
        return this.sendRequest(
            'get',
            urls[dataType],
            params,
            null,
            RestDataSource.getAuthorizationHeader()
        );
    }

    getEntity = (dataType, id) => {
        return this.sendRequest(
            'get',
            urls[dataType] + '/' + id,
            null,
            null,
            RestDataSource.getAuthorizationHeader()
        );
    }

    saveEntity = (dataType, id, data) => {
        return this.sendRequest(
            id ? 'put' : 'post',
            id ? urls[dataType] + '/' + id : urls[dataType],
            null,
            data,
            RestDataSource.getAuthorizationHeader()
        );
    }

    deleteEntity = (dataType, id) => {
        return this.sendRequest(
            'delete',
            urls[dataType] + '/' + id,
            null,
            null,
            RestDataSource.getAuthorizationHeader()
        );
    }

    login = (credentials) => {
        const data = {
            ...credentials,
            "grant_type": "password"
        };
        const clientToken = 'dGVzdGNsaWVudDp0ZXN0cGFzcw==';
        const headers = {
            'Authorization': `Basic ${clientToken}`
        }
        return this.sendRequest('post', urls.login, null, data, headers);
    }

    logout = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const data = {
                token: user.access_token,
                token_type_hint: 'access_token'
            };
            return this.sendRequest('post', urls.logout, null, data);
        }
    }

    register = (data) => {
        /*const clientToken = 'dGVzdGNsaWVudDp0ZXN0cGFzcw==';
        const headers = {
            'Authorization': `Basic ${clientToken}`
        }*/
        return this.sendRequest('post', urls.register, null, data);
    }

    recoverPassword = (data) => {
        /*const clientToken = 'dGVzdGNsaWVudDp0ZXN0cGFzcw==';
        const headers = {
            'Authorization': `Basic ${clientToken}`
        }*/
        return this.sendRequest('post', urls.recover_password, null, data);
    }

    setPassword = (data) => {
        /*const clientToken = 'dGVzdGNsaWVudDp0ZXN0cGFzcw==';
        const headers = {
            'Authorization': `Basic ${clientToken}`
        }*/
        return this.sendRequest('post', urls.set_password, null, data);
    }

    getFile = (code) => {
        return axios.request({
            method: 'get',
            url: urls.get_file + '/' + code,
            responseType: 'blob'
        });
    }

    profile = () => {
        //console.log(RestDataSource.getAuthorizationHeader());
        return this.sendRequest(
            'get',
            urls.profile,
            null,
            null,
            RestDataSource.getAuthorizationHeader()
        );
    }

    getList = (listId) => {
        return this.sendRequest(
            'get',
            urls.lists + '/' + listId,
            null,
            null,
            RestDataSource.getAuthorizationHeader()
        );
    }

    printRepertoire = (id, part) => {
        /*return this.sendRequest(
            'get',
            urls.print_repertoire + '/' + id,
            null,
            null,
            null,//RestDataSource.getAuthorizationHeader(),
            'blob'
        );*/
        return axios.request({
            method: 'get',
            url: urls.print_repertoire + '/' + id,
            responseType: 'blob',
            params: {part: part}
        });
    }

    getEvent = (id, part) => {
        return this.sendRequest(
            'get',
            urls.e + '/' + id + '/' + part
        );
    }
}
