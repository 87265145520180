import React, {Component} from "react";
import {connect} from "react-redux";
import {
    loadingError,
    getEvent
} from "../../data/actionCreators";
import LyricsContainer from "./LyricsContainer";
import {Spin} from "antd";
import {errorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    loadEvent: (id, history, onSuccess) => {
        //dispatch(startLoader('Pobieranie danych wydarzenia'));
        dispatch(getEvent(id, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    }
});

export const LyricsConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        render() {
            return <Spin spinning={this.props.loading} tip={this.props.text}>
                <LyricsContainer {...this.props}/>
            </Spin>
        }
    }
);
