import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import {Empty, Row, Col, Button, Space, Popconfirm} from 'antd';
import {amIAdmin} from "../../helpers/auth";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {CompositionYoutubeModal} from "./CompositionYoutubeModal";

export class CompositionYoutube extends Component {
    state = {
        youtubeModalVisible: false,
        youtubeModalTitle: 'Dodaj link',
        currentLink: {}
    }

    prepareLinks = (links) => {
        return links.length ? <Row gutter={16}>
            {
                links.map((link, key) => <Col xs={24} sm={24} md={12} key={key}>
                    <div>
                        <h3>{link.link_title || 'Bez tytułu'}</h3>
                        <ReactPlayer
                            url={link.link}
                            controls={true}
                            width='100%'
                            style={{marginBottom: '1em'}}
                        />
                        {amIAdmin() ? <Popconfirm
                            title="Czy na pewno usunąć link?"
                            onConfirm={() => this.onDeleteLink(link)}
                            okText="Tak"
                            cancelText="Nie"
                        >
                            <Button type="primary"
                                    danger
                                    icon={<DeleteOutlined/>}
                                    title={'Usuń link'}
                            >
                                Usuń link
                            </Button>
                        </Popconfirm> : null}
                    </div>
                </Col>)
            }
        </Row> : <Empty description={'Brak linków'}/>;
    }

    showYoutubeModal = () => {
        this.setState({youtubeModalVisible: true, youtubeModalTitle: 'Dodaj link'});
    }

    onFileOk = (data) => {
        data.composition_id = this.props.composition.id;
        this.props.addLink(data);
        this.setState({youtubeModalVisible: false});
    }

    onFileCancel = () => {
        this.setState({youtubeModalVisible: false});
    }

    onDeleteLink = (data) => {
        this.props.deleteLink(data);
    }

    render() {
        return <div style={{marginTop: '1em'}}>
            {amIAdmin() ? <div style={{paddingBottom: '1em'}}>
                <Space>
                    <Button type="primary"
                            icon={<PlusOutlined/>}
                            onClick={this.showYoutubeModal}
                            title={'Dodaj link do nagrania na Youtube'}>
                        Dodaj link
                    </Button>
                    <CompositionYoutubeModal
                        visible={this.state.youtubeModalVisible}
                        title={this.state.youtubeModalTitle}
                        handleOk={this.onFileOk}
                        handleCancel={this.onFileCancel}
                        data={this.state.currentLink}></CompositionYoutubeModal>
                </Space>
            </div> : null}
            {this.prepareLinks(this.props.links)}
        </div>
    }
}
