import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getCollection,
    getEntity,
    saveEntity,
    loadingError,
    startLoading,
    getList,
    startLoader,
    searchCompositions
} from "../../data/actionCreators";
import CalendarContainer from "./CalendarContainer";
import {Spin} from "antd";
import {errorMessage, updateErrorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    loadCollection: (dataType, params, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Pobieranie danych kalendarza'));
        dispatch(getCollection(dataType, params, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    loadEntity: (dataType, id, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Pobieranie danych wydarzenia'));
        dispatch(getEntity(dataType, id, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    saveEvent: (dataType, id, data, params, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Aktualizacja wydarzenia'));
        dispatch(saveEntity(dataType, id, data, () => {
            dispatch(startLoading(dataType, 'Pobieranie danych kalendarza'));
            dispatch(getCollection(dataType, params, onSuccess, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    loadList: (listId, history) => {
        dispatch(startLoader('Pobieranie słownika'));
        dispatch(getList(listId, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    searchCompositions: (search, history, onSuccess) => {
        dispatch(startLoader('Wyszukiwanie utworów'));
        dispatch(searchCompositions(search, (data) => {
            onSuccess(data);
        }, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    }
});

export const CalendarConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        render() {
            return <Spin spinning={this.props.loading} tip={this.props.text}>
                <CalendarContainer {...this.props}/>
            </Spin>
        }
    }
);
