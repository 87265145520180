import React, {Component} from 'react';
import {Layout, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import {clefs} from "./helper";
import {MusicXml} from "./MusicXml";

const {Content} = Layout;

export default class ClefsContainer extends Component {

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Klucze
                    </Typography.Title>

                    <p style={{marginTop: '16px'}}>
                        Klucz – znak graficzny, który wyznacza położenie na pięciolinii jednego dźwięku, a w
                        związku
                        z
                        tym i pozostałych, określanych w stosunku do niego. Kształt kluczy pochodzi od liter
                        alfabetu
                        umieszczanych na początku linii do X wieku.
                        Litery te z czasem zostały przestylizowane i przyjęły kształt dzisiejszych kluczy,
                        dzielonych na
                        trzy grupy: kluczy G, F i C.
                    </p>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {clefs.map((item, key) => {
                            return <div key={key} style={{width: '300px', textAlign: 'center'}}>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildClefMusicXML(item)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <div>{item.name}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
