import React from 'react';
import {Route} from 'react-router-dom';
import {Layout} from "antd";

const EmptyLayout = ({children}) => (
    <Layout style={{overflow: 'auto', backgroundColor: 'white', padding: '1em 0.4em'}}>
        {children}
    </Layout>
);

const EmptyLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <EmptyLayout>
                <Component {...matchProps} />
            </EmptyLayout>
        )}/>
    )
};

export default EmptyLayoutRoute;
