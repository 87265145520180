export const majorScale = [
    {
        name: 'C-dur',
        notes: [['C', 4, 0], ['D', 4, 0], ['E', 4, 0], ['F', 4, 0], ['G', 4, 0], ['A', 4, 0], ['B', 4, 0], ['C', 5, 0]],
        fifths: 0
    },
    {
        name: 'G-dur',
        notes: [['G', 4, 0], ['A', 4, 0], ['B', 4, 0], ['C', 5, 0], ['D', 5, 0], ['E', 5, 0], ['F', 5, 1], ['G', 5, 0]],
        fifths: 1
    },
    {
        name: 'D-dur',
        notes: [['D', 4, 0], ['E', 4, 0], ['F', 4, 1], ['G', 4, 0], ['A', 4, 0], ['B', 4, 0], ['C', 5, 1], ['D', 5, 0]],
        fifths: 2
    },
    {
        name: 'A-dur',
        notes: [['A', 4, 0], ['B', 4, 0], ['C', 5, 1], ['D', 5, 0], ['E', 5, 0], ['F', 5, 1], ['G', 5, 1], ['A', 5, 0]],
        fifths: 3
    },
    {
        name: 'E-dur',
        notes: [['E', 4, 0], ['F', 4, 1], ['G', 4, 1], ['A', 4, 0], ['B', 4, 0], ['C', 5, 1], ['D', 5, 1], ['E', 5, 0]],
        fifths: 4
    },
    {
        name: 'H-dur',
        notes: [['B', 3, 0], ['C', 4, 1], ['D', 4, 1], ['E', 4, 0], ['F', 4, 1], ['G', 4, 1], ['A', 4, 1], ['B', 4, 0]],
        fifths: 5
    },
    {
        name: 'Fis-dur',
        notes: [['F', 4, 1], ['G', 4, 1], ['A', 4, 1], ['B', 4, 0], ['C', 5, 1], ['D', 5, 1], ['E', 5, 1], ['F', 5, 1]],
        fifths: 6
    },
    {
        name: 'Cis-dur',
        notes: [['C', 4, 1], ['D', 4, 1], ['E', 4, 1], ['F', 4, 1], ['G', 4, 1], ['A', 4, 1], ['B', 4, 1], ['C', 5, 1]],
        fifths: 7
    },
    {
        name: 'F-dur',
        notes: [['F', 4, 0], ['G', 4, 0], ['A', 4, 0], ['B', 4, -1], ['C', 5, 0], ['D', 5, 0], ['E', 5, 0], ['F', 5, 0]],
        fifths: -1
    },
    {
        name: 'B-dur',
        notes: [['B', 4, -1], ['C', 5, 0], ['D', 5, 0], ['E', 5, -1], ['F', 5, 0], ['G', 5, 0], ['A', 5, 0], ['B', 5, -1]],
        fifths: -2
    },
    {
        name: 'Es-dur',
        notes: [['E', 4, -1], ['F', 4, 0], ['G', 4, 0], ['A', 4, -1], ['B', 4, -1], ['C', 5, 0], ['D', 5, 0], ['E', 5, -1]],
        fifths: -3
    },
    {
        name: 'As-dur',
        notes: [['A', 4, -1], ['B', 4, -1], ['C', 5, 0], ['D', 5, -1], ['E', 5, -1], ['F', 5, 0], ['G', 5, 0], ['A', 5, -1]],
        fifths: -4
    },
    {
        name: 'Des-dur',
        notes: [['D', 4, -1], ['E', 4, -1], ['F', 4, 0], ['G', 4, -1], ['A', 4, -1], ['B', 4, -1], ['C', 5, 0], ['D', 5, -1]],
        fifths: -5
    },
    {
        name: 'Ges-dur',
        notes: [['G', 4, -1], ['A', 4, -1], ['B', 4, -1], ['C', 5, -1], ['D', 5, -1], ['E', 5, -1], ['F', 5, 0], ['G', 5, -1]],
        fifths: -6
    },
    {
        name: 'Ces-dur',
        notes: [['C', 4, -1], ['D', 4, -1], ['E', 4, -1], ['F', 4, -1], ['G', 4, -1], ['A', 4, -1], ['B', 4, -1], ['C', 5, -1]],
        fifths: -7
    }
];