import React, {Component} from "react";
import RecoverPasswordForm from "./RecoverPasswordForm";

export class RecoverPasswordContainer extends Component {

    handleSubmit = (data, onSuccess) => {
        this.props.recoverPassword(data, this.props.history, onSuccess);
    }

    render() {
        return <RecoverPasswordForm
            handleSubmit={this.handleSubmit}
        />;
    }
}
