import React, {Component} from "react";
import RegisterForm from "./RegisterForm";

export class RegisterContainer extends Component {

    handleSubmit = (data, onSuccess) => {
        delete data.confirm;
        this.props.register(data, this.props.history, onSuccess);
    }

    render() {
        return <RegisterForm
            handleSubmit={this.handleSubmit}
        />;
    }
}
