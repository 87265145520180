export const soundToPitches = (sound) => {
    const soundMap = {
        'C': 0,
        'D': 2,
        'E': 4,
        'F': 5,
        'G': 7,
        'A': 9,
        'B': 11
    }
    return soundMap[sound[0]] + (12 * (sound[1] + 1)) + sound[2];
}

export const octaves = ['subkontra', 'kontra', 'wielkie', 'małe', 'razkreślne', 'dwukreślne', 'trzykreślne', 'czterokreślne', 'pięciokreślne'];

export const getSoundName = (item, withOctave = true) => {
    let suffix = '';
    if (item[2] > 0) {
        for (let i = 0; i < item[2]; i++) {
            suffix += 'is';
        }
    } else if (item[2] < 0) {
        suffix = 'es';
        //wyjątki
        if (item[0] === 'E' || item[0] === 'A') {
            suffix = 's';
        } else if (item[0] === 'B') {
            suffix = '';
        }
        if (item[2] < -1) {
            if (item[0] === 'B') {
                suffix = 'es';
            }
            for (let i = 0; i < Math.abs(item[2]) - 1; i++) {
                suffix += 'es';
            }
        }
    }
    let baseName = item[0] === 'B' && item[2] !== -1 ? 'H' : item[0];
    baseName = (item[1] > 2) ? baseName.toLowerCase() : baseName;
    let result = `${baseName}${suffix}`;
    if (withOctave) {
        result += ' ' + octaves[item[1]];
    }
    return result;
}

export const clefs = [
    {
        name: 'klucz G - wiolinowy',
        sign: 'G',
        line: 2,
        octave: 4
    },
    {
        name: 'klucz F - basowy',
        sign: 'F',
        line: 4,
        octave: 3
    },
    {
        name: 'klucz C - altowy',
        sign: 'C',
        line: 3,
        octave: 4
    }
];

export const intervalNames = [
    {
        name: 'pryma czysta',
        code: '1'
    },
    {
        name: 'sekunda mała',
        code: '2>'
    },
    {
        name: 'sekunda wielka',
        code: '2'
    },
    {
        name: 'tercja mała',
        code: '3>'
    },
    {
        name: 'tercja wielka',
        code: '3'
    },
    {
        name: 'kwarta czysta',
        code: '4'
    },
    {
        name: 'kwarta zwiększona',
        code: '4<'
    },
    {
        name: 'kwinta zmniejszona',
        code: '5>'
    },
    {
        name: 'kwinta czysta',
        code: '5'
    },
    {
        name: 'seksta mała',
        code: '6>'
    },
    {
        name: 'seksta wielka',
        code: '6'
    },
    {
        name: 'septyma mała',
        code: '7'
    },
    {
        name: 'septyma wielka',
        code: '7<'
    },
    {
        name: 'oktawa czysta',
        code: '8'
    }
];

export const soundNames = [
    ['C', -1, 'ces'],
    ['C', 0, 'c'],
    ['C', 1, 'cis'],
    ['D', -1, 'des'],
    ['D', 0, 'd'],
    ['D', 1, 'dis'],
    ['E', -1, 'es'],
    ['E', 0, 'e'],
    ['E', 1, 'eis'],
    ['F', -1, 'fes'],
    ['F', 0, 'f'],
    ['F', 1, 'fis'],
    ['G', -1, 'ges'],
    ['G', 0, 'g'],
    ['G', 1, 'gis'],
    ['A', -1, 'as'],
    ['A', 0, 'a'],
    ['A', 1, 'ais'],
    ['B', -1, 'b'],
    ['B', 0, 'h'],
    ['B', 1, 'his'],
];

export const noteValues = [
    {
        name: 'cała nuta',
        type: 'whole',
        rest: false,
        value: 1
    },
    {
        name: 'półnuta',
        type: 'half',
        rest: false,
        value: 0.5
    },
    {
        name: 'ćwierćnuta',
        type: 'quarter',
        rest: false,
        value: 0.25
    },
    {
        name: 'ósemka',
        type: 'eighth',
        rest: false,
        value: 0.125
    },
    {
        name: 'szesnastka',
        type: '16th',
        rest: false,
        value: 0.0625
    },
    {
        name: 'trzydziestodwójka',
        type: '32th',
        rest: false,
        value: 0.03125
    },
    {
        name: 'sześćdziesięcioczwórka',
        type: '64th',
        rest: false,
        value: 0.015625
    },
    {
        name: 'pauza całonutowa',
        type: 'whole',
        rest: true,
        value: 1
    },
    {
        name: 'pazua półnutowa',
        type: 'half',
        rest: true,
        value: 0.5
    },
    {
        name: 'pauza ćwierćnutowa',
        type: 'quarter',
        rest: true,
        value: 0.25
    },
    {
        name: 'pauza ósemkowa',
        type: 'eighth',
        rest: true,
        value: 0.125
    },
    {
        name: 'pauza szesnastkowa',
        type: '16th',
        rest: true,
        value: 0.0625
    },
    {
        name: 'pauza trzydziestodwójkowa',
        type: '32th',
        rest: true,
        value: 0.03125
    },
    {
        name: 'pauza sześćdziesięcioczwórkowa',
        type: '64th',
        rest: true,
        value: 0.015625
    }
];

export const triadNames = [
    {
        name: 'zwiększony'
    },
    {
        name: 'durowy'
    },
    {
        name: 'molowy'
    },
    {
        name: 'zmniejszony'
    }
];

export const playCorrect = () => {
    let sound = new Audio('/img/audio/applause.mp3');
    sound.play();
}

export const playIncorrect = () => {
    let sound = new Audio('/img/audio/fail.mp3');
    sound.play();
}

export const getInversion = (interval) => {
    switch (interval.name) {
        case 'pryma czysta':
            return 'oktawa czysta';
        case 'sekunda mała':
            return 'septyma wielka';
        case 'sekunda wielka':
            return 'septyma mała';
        case 'tercja mała':
            return 'seksta wielka';
        case 'tercja wielka':
            return 'seksta mała';
        case 'kwarta czysta':
            return 'kwinta czysta';
        case 'kwarta zwiększona':
            return 'kwinta zmniejszona';
        case 'kwinta zmniejszona':
            return 'kwarta zwiększona';
        case 'kwinta czysta':
            return 'kwarta czysta';
        case 'seksta mała':
            return 'tercja wielka';
        case 'seksta wielka':
            return 'tercja mała';
        case 'septyma mała':
            return 'sekunda wielka';
        case 'septyma wielka':
            return 'sekunda mała';
        case 'oktawa czysta':
            return 'pryma czysta';
        default:
            return '';
    }
}

export const drawExercise = (exercises) => {
    const min = 0;
    const max = exercises.length - 1;
    const key = Math.floor(Math.random() * (max - min + 1) + min);
    return exercises[key];
}