import React, {Component} from "react";
import {Form, Input, Button} from 'antd';
import {
    UserOutlined,
    LockOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";

class LoginForm extends Component {

    formRef = React.createRef();

    handleSubmit = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                this.props.handleSubmit(values, () => {
                    this.formRef.current.resetFields();
                });
                this.formRef.current.setFieldsValue({password: ''});
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    render() {
        return (
            <React.Fragment>
                <h1 style={{
                    margin: '0 auto',
                    background: 'none',
                    padding: '2em 0 1em 0',
                    textAlign: 'center'
                }}>Zaloguj się</h1>
                <Form onFinish={this.handleSubmit}
                      ref={this.formRef}
                      className="login-form"
                >
                    <Form.Item name="username"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Proszę wprowadzić nazwę użytkownika'
                                   }
                               ]}>
                        <Input
                            prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Nazwa użytkownika"
                        />
                    </Form.Item>
                    <Form.Item name="password"
                               rules={[{required: true, message: 'Proszę wprowadzić hasło'}]}>
                        <Input
                            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            type="password"
                            placeholder="Hasło"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Zaloguj
                        </Button>
                        <Link style={{float: 'left'}} to="/recover-password">
                            Nie pamiętam hasła
                        </Link>
                        <Link style={{float: 'right'}} to="/register">Zarejestruj się</Link>
                    </Form.Item>
                </Form>
            </React.Fragment>
        );
    }
};

export default LoginForm;
