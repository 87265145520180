import React, {Component} from 'react';
import {AutoComplete, Spin} from "antd";
import "./CompositionInput.css";

const {Option} = AutoComplete;

const empty = {text: '', id: 0, record: {}};

export class CompositionInput extends Component {

    static getDerivedStateFromProps(props, state) {
        // Should be a controlled component.
        if ('value' in props) {
            /*if (typeof props.value === 'string') {
                return {value: {text: props.value, id: 0}};
            }*/
            return {value: props.value || empty};
        }
        return {value: empty};
    }

    constructor(props) {
        super(props);

        let value = props.value || empty;
        /*if (typeof value === 'string') {
            value = {text: value, id: 0};
        }*/
        this.state = {
            value: value
        };
    }

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const {onChange} = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    };

    handleSearch = (v) => {
        let {value, data} = this.state;
        if (typeof value === 'string') {
            value = {};
        }
        value.text = v;
        if (v.length < 4) {
            value.id = 0;
            value.record = {};
            data = [];
        }
        this.setState({value: value, data: data});
        this.triggerChange(value);
        if (v && v.length > 3) {
            this.props.onSearch(v);
        }
    }

    handleSelect = (v, option) => {
        const {value} = this.state;
        console.log('handleSelect', v, option);
        value.id = option.key;
        value.text = option.record.title;
        value.record = option.record;
        this.setState({value: value});
        this.triggerChange(value);
    }

    render() {
        let compositionOptions = [];
        if (this.props.data) {
            compositionOptions = this.props.data.map((record) => {
                return (<Option key={record.id} value={record.id} record={record}>
                    {record.title}
                </Option>)
            });
        }

        return (
            <Spin spinning={this.props.searching}>
                <AutoComplete
                    style={{width: '100%'}}
                    value={this.state.value.text}
                    onSearch={this.handleSearch}
                    onSelect={this.handleSelect}
                >
                    {compositionOptions}
                </AutoComplete>
                <input type="hidden" value={this.state.value.id ? this.state.value.id : 0}/>
            </Spin>
        );
    }
}
