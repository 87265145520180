import React, {Component} from "react";
import {Col, Layout, Row} from "antd";
import {BookOutlined, CalendarOutlined, PlayCircleOutlined} from "@ant-design/icons";

const {Content} = Layout;

const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
    gap: '10px'
};

class HomeContainer extends Component {

    render() {
        return <Layout style={{padding: '0 24px 24px'}}>
            <Content style={{
                background: '#fff',
                padding: 24,
                margin: 0,
            }}>
                <div className="site-card-wrapper">
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <a href={'/songbook'} title={'Przejdź do śpiewnika'}>
                                <div style={cardStyles}>
                                    <BookOutlined style={{fontSize: '5em'}}/>
                                    <h2>Śpiewnik</h2>
                                </div>
                            </a>
                        </Col>
                        <Col xs={24} md={8}>
                            <a href={'/calendar'} title={'Przejdź do kalendarza'}>
                                <div style={cardStyles}>
                                    <CalendarOutlined style={{fontSize: '5em'}}/>
                                    <h2>Kalendarz</h2>
                                </div>
                            </a>
                        </Col>
                        <Col xs={24} md={8}>
                            <a href={'/exercises/clefs'} title={'Przejdź do ćwiczeń'}>
                                <div style={cardStyles}>
                                    <PlayCircleOutlined style={{fontSize: '5em'}}/>
                                    <h2>Ćwiczenia</h2>
                                </div>
                            </a>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>;
    }
}

export default HomeContainer;
