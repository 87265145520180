import React, {Component} from 'react';
import {Button, Layout, Tag, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import MIDISounds from 'midi-sounds-react';
import {CaretRightOutlined} from '@ant-design/icons';
import {notes} from './notes';
import {getSoundName} from "./helper";
import {MidiPlayer} from "./MidiPlayer";
import {MusicXml} from "./MusicXml";

const {Content} = Layout;

export default class NotesContainer extends Component {

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Nazwy dźwięków
                    </Typography.Title>

                    <p style={{marginTop: '16px'}}>
                        Nazwy literowe dźwięków: <Tag>c</Tag> <Tag>d</Tag> <Tag>e</Tag> <Tag>f</Tag> <Tag>g</Tag>
                        <Tag>a</Tag> <Tag>h</Tag>.
                        Nazwy solmizacyjne: <Tag>do</Tag> <Tag>re</Tag> <Tag>mi</Tag> <Tag>fa</Tag> <Tag>sol</Tag>
                        <Tag>la</Tag> <Tag>si</Tag>.
                        Dźwięki podwyższone krzyżykiem <Tag>♯</Tag> nazywamy dodając do nazwy podstawowej
                        przyrostek <Tag>-is</Tag>.
                        Dźwięki obniżone bemolem <Tag>♭</Tag> nazywamy dodając do nazwy podstawowej
                        przyrostek <Tag>-es</Tag>.
                        Wyjątki: <Tag>h</Tag> + <Tag>♭</Tag> = <Tag>b</Tag>, <Tag>a</Tag> + <Tag>♭</Tag> = <Tag>as</Tag>, <Tag>e</Tag> + <Tag>♭</Tag> = <Tag>es</Tag>.
                    </p>

                    <div style={{display: 'none'}}>
                        <MIDISounds
                            ref={(ref) => (this.midiSounds = ref)}
                            appElementName="root" instruments={[3]}
                        />
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {notes.map((item, key) => {
                            return <div key={key} style={{width: '300px', textAlign: 'center'}}>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildNoteMusicXML(item)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <Button icon={<CaretRightOutlined/>} onClick={() => MidiPlayer.playNote(this.midiSounds, item)}></Button>
                                    <div>{getSoundName(item)}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
