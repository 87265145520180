import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getCollection,
    getEntity,
    saveEntity,
    loadingError,
    startLoading,
    getList,
    startLoader,
    searchCompositions,
    printRepertoire
} from "../../data/actionCreators";
import RepertoireContainer from "./RepertoireContainer";
import {Spin} from "antd";
import {errorMessage, updateErrorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    loadCollection: (dataType, params, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Pobieranie danych kalendarza'));
        dispatch(getCollection(dataType, params, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    loadEntity: (dataType, id, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Pobieranie danych wydarzenia'));
        dispatch(getEntity(dataType, id, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    saveEvent: (dataType, id, data, params, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Aktualizacja repertuaru'));
        dispatch(saveEntity(dataType, id, data, () => {
            dispatch(startLoading(dataType, 'Pobieranie repertuaru'));
            dispatch(getEntity(dataType, id, onSuccess, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    loadList: (listId, history) => {
        dispatch(startLoader('Pobieranie słownika'));
        dispatch(getList(listId, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    searchCompositions: (search, history, onSuccess) => {
        dispatch(startLoader('Wyszukiwanie utworów'));
        dispatch(searchCompositions(search, (data) => {
            onSuccess(data);
        }, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    printRepertoire: (id, part = 'choir', history) => {
        dispatch(startLoader('Drukowanie repertuaru'));
        dispatch(printRepertoire(id, part,(data) => {
                const file = new Blob(
                    [data],
                    {type: 'application/pdf'});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
                }, (error) => {
                    dispatch(loadingError(error));
                    errorMessage(error, history);
                }
            )
        );
    }
});

export const RepertoireConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        render() {
            return <Spin spinning={this.props.loading} tip={this.props.text}>
                <RepertoireContainer {...this.props}/>
            </Spin>
        }
    }
);
