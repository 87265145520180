import React, {Component} from 'react';
import {Button, Layout, Tag, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import MIDISounds from 'midi-sounds-react';
import {CaretRightOutlined} from '@ant-design/icons';
import {triads} from "./triads";
import {MidiPlayer} from "./MidiPlayer";
import {MusicXml} from "./MusicXml";

const {Content} = Layout;

export default class TriadsContainer extends Component {

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Rodzaje trójdźwięków
                    </Typography.Title>

                    <p style={{marginTop: '16px'}}>
                        Trójdźwięk – najprostszy akord o budowie tercjowej, składający się z trzech dźwięków nazywanych
                        (kolejno): prymą (podstawa akordu), tercją i kwintą. Pryma akordu decyduje o jego nazwie. Tercja
                        i kwinta akordu decydują o jego trybie
                        (<Tag>molowy</Tag>, <Tag>durowy</Tag>, <Tag>zmniejszony</Tag> lub <Tag>zwiększony</Tag>).
                        Akord molowy zbudowany jest z tercji małej i tercji wielkiej.
                        Akord durowy zbudowany jest z tercji wielkiej i tercji małej.
                        Akord zmniejszony zbudowany jest z dwóch tercji małych, natomiast akord zwiększony z dwóch tercji
                        wielkich.
                        Dźwięki wchodzące w skład trójdźwięku nazywa się składnikami trójdźwięku.
                    </p>

                    <div style={{display: 'none'}}>
                        <MIDISounds
                            ref={(ref) => (this.midiSounds = ref)}
                            appElementName="root" instruments={[3]}
                        />
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {triads.map((item, key) => {
                            return <div key={key} style={{width: '270px', textAlign: 'center'}}>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildTriadMusicXML(item, true)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    stretchLastSystemLine={true}
                                />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <Button icon={<CaretRightOutlined/>} onClick={() => MidiPlayer.playTriad(this.midiSounds, item)}></Button>
                                    <div>{item.name}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
