import React, {Component} from "react";
import {connect} from "react-redux";
import {startLoader, login, logout, loginError, profile, profileError} from "../../data/actionCreators";
import {Spin} from "antd";
import {LoginContainer} from "./LoginContainer";
import {loginErrorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    login: (credentials, history, onSuccess) => {
        dispatch(startLoader());
        dispatch(login(credentials, (response) => {
            localStorage.setItem('user', JSON.stringify(response.data));
            onSuccess();
            dispatch(startLoader());
            dispatch(profile((response2) => {
                localStorage.setItem('user', JSON.stringify({...response.data, profile: response2.data}));
                history.push('/');
            }, (error) => {
                dispatch(profileError(error));
            }));
        }, (error) => {
            dispatch(loginError(error));
            loginErrorMessage(error);
        }));
    },
    logout: () => {
        dispatch(startLoader());
        dispatch(logout());
    }
});

export const LoginConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {

        componentDidMount() {
            this.props.logout();
        }

        render() {
            return <Spin spinning={this.props.loading}>
                <LoginContainer {...this.props}/>
            </Spin>
        }
    }
);
