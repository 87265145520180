export const getUsername = () => {
    const us = localStorage.getItem('user');
    if (us) {
        const up = JSON.parse(us);
        return up.profile ? up.profile.username: null;
    }
    return null;
}

export const getRoles = () => {
    const us = localStorage.getItem('user');
    if (us) {
        const up = JSON.parse(us);
        if (up.profile && up.profile.roles) {
            return up.profile.roles.split(';');
        }
    }
    return [];
}

export const amIAdmin = () => {
    const roles = getRoles();
    if (roles.indexOf('administrator') !== -1) {
        return true;
    }
    return false;
}
