import React, {Component} from 'react';
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";

export class CompositionScore extends Component {

    render() {
        return <OpenSheetMusicDisplay
            file={this.props.file}
            drawTitle={false}
            autoResize={true}
            backend={'svg'}
            drawCredits={false}
            /*defaultFontFamily={"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial"}*/
        />
    }
}
