import {ActionTypes, DataTypes} from "./types";

const initialState = {
    [`${DataTypes.COMPOSITIONS}_params`]: {
        limit: 20,
        page: 1,
        sort: {column: 'title', order: 'ascend'},
        title: ''
    },
    [`${DataTypes.COMPOSITIONS}_entity`]: {},

    [`${DataTypes.SOURCES}_params`]: {
        page: 1,
        sort: {column: 'title', order: 'ascend'}
    },
    [`${DataTypes.CATEGORIES}_params`]: {
        page: 1,
        sort: {column: 'name', order: 'ascend'}
    },
    [`${DataTypes.FEASTS}_params`]: {
        start: null,
        end: null
    },
    [`${DataTypes.FEASTS}_entity`]: {},
    [`${DataTypes.EVENTS}_params`]: {
        start: null,
        end: null
    },
    [`${DataTypes.EVENTS}_entity`]: {},
    [`${DataTypes.EVENT_PARTS}_params`]: {
        limit: 100,
        page: 1,
        sort: {column: 'id', order: 'ascend'}
    },
    [`${DataTypes.EVENT_PARTS}_entity`]: {},
    [`${DataTypes.PARTS}_params`]: {
        limit: 100,
        page: 1,
        sort: {column: 'name', order: 'ascend'}
    },
    lists: {},
    loading: false,
    file: null
}

export const MainReducer = (storeData = initialState, action) => {
    switch (action.type) {

        case ActionTypes.START_LOADING:
            //console.log('reducer startLoading', action.payload.text);
            return {
                ...storeData,
                loading: true,
                text: action.payload.text
            }

        case ActionTypes.GET_COLLECTION:
            return {
                ...storeData,
                [action.payload.dataType]: action.payload.data,
                [`${action.payload.dataType}_total`]: action.payload.total,
                [`${action.payload.dataType}_params`]: action.payload.params,
                loading: false,
                text: ''
            };

        case ActionTypes.GET_ENTITY:
            //console.log('reducer getEntity', action.payload);
            return {
                ...storeData,
                [`${action.payload.dataType}_entity`]: action.payload.data,
                loading: false,
                text: '',
                file: null
            };

        case ActionTypes.SAVE_ENTITY:
            return {
                ...storeData,
                loading: false,
                text: '',
                file: null
            };

        case ActionTypes.START_LOADER:
            return {
                ...storeData,
                loading: true,
                text: action.payload.text
            };

        case ActionTypes.LOADING_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.LOGIN:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.LOGIN_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.LOGOUT:
            localStorage.removeItem('user');
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.REGISTER:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.REGISTER_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.RECOVER_PASSWORD:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.RECOVER_PASSWORD_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.SET_PASSWORD:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.SET_PASSWORD_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.DOWNLOAD_FILE:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.DOWNLOAD_FILE_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.OPEN_FILE:
            //console.log(action.payload);
            return {
                ...storeData,
                loading: false,
                file: action.payload
            }

        case ActionTypes.OPEN_FILE_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.DELETE_FILE:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.DELETE_FILE_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.PROFILE:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.PROFILE_ERROR:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.GET_LIST:
            return {
                ...storeData,
                lists: {...storeData.lists, [action.payload.listId]: action.payload.data},
                loading: false,
                text: ''
            };

        case ActionTypes.SEARCH_COMPOSITIONS:
            return {
                ...storeData,
                loading: false
            }

        case ActionTypes.PRINT_REPERTOIRE:
            return {
                ...storeData,
                loading: false
            }

        default:
            return storeData;
    }
}
