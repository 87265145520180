import React, {Component} from 'react';
import {Button, Layout, Typography} from "antd";
import OpenSheetMusicDisplay from "../../components/OpenSheetMusicDisplay";
import MIDISounds from 'midi-sounds-react';
import {CaretRightOutlined} from '@ant-design/icons';
import {MidiPlayer} from "./MidiPlayer";
import {MusicXml} from "./MusicXml";
import {minorScale} from "./minorScale";

const {Content} = Layout;

export default class MinorScaleContainer extends Component {

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>
            <Content style={{padding: '12px'}}>
                <div style={{textAlign: 'left'}}>
                    <Typography.Title level={3} style={{textTransform: 'uppercase', margin: '0'}}>
                        Skala molowa
                    </Typography.Title>

                    <p style={{marginTop: '16px'}}>
                        Skala molowa (z łac. mollis – miękki), zwana też minorową (z łac. minor – mniejszy, odnosi się
                        do tercji małej zawartej między I a III stopniem skali) – siedmiostopniowa skala z
                        charakterystycznym półtonem między stopniami II i III. Uważa się skale molowe i oparte na nich
                        utwory muzyczne za mające smutne brzmienie, w odróżnieniu od skal durowych.
                    </p>

                    <div style={{display: 'none'}}>
                        <MIDISounds
                            ref={(ref) => (this.midiSounds = ref)}
                            appElementName="root" instruments={[3]}
                        />
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'}}>
                        {minorScale.map((item, key) => {
                            return <div key={key} style={{width: '100%', textAlign: 'left'}}>
                                <Typography.Title level={4}>Gama {item.name}</Typography.Title>
                                <OpenSheetMusicDisplay
                                    file={MusicXml.buildScaleMusicXML(item)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                />
                                {item.fifths !== 0 ? <OpenSheetMusicDisplay
                                    file={MusicXml.buildScaleMusicXML(item, true)}
                                    drawTitle={false}
                                    autoResize={true}
                                    backend={'svg'}
                                    drawPartNames={false}
                                    drawCredits={false}
                                    drawTimeSignatures={false}
                                    stretchLastSystemLine={true}
                                /> : null}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }}>
                                    <Button icon={<CaretRightOutlined/>}
                                            onClick={() => MidiPlayer.playScale(this.midiSounds, item)}>
                                        Zagraj
                                    </Button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Content>
        </Layout>
    }
}
