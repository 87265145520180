import React, {Component} from 'react';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {DeleteOutlined, DragOutlined} from "@ant-design/icons";
import {Button, Col, Empty, Row} from "antd";
import './Sortable.css';
import {amIAdmin} from "../../helpers/auth";
import {Link} from "react-router-dom";

const DragHandle = sortableHandle(() => <DragOutlined title="Przenieś"
                                                      className="SortableHandle"
                                                      style={{marginRight: '1em', fontSize: '22px'}}/>);

const SortableItem = sortableElement(({idx, value, onRemove, onEdit}) => (
    <li className="SortableItem">
        <div className="SortableMeta">
            <DragHandle/>
            <div style={{flex: 1}}>
                <Row>
                    <Col span={6}>
                        {value.event_part_name}
                    </Col>
                    <Col span={12}>
                        <Link to={"/songbook/" + value.composition_id}>{(value.composition_title || '')}</Link>
                    </Col>
                    <Col span={6} style={{fontSize: '0.8em'}}>
                        {(value.info || '')}
                    </Col>
                </Row>
            </div>
        </div>
        <ul className="SortableAction">
            <li>
                {/*<Button title="Edytuj utwór"
                                onClick={() => {
                                    onEdit(value)
                                }}>
                            <EditOutlined/>
                        </Button>*/}
                {
                    amIAdmin() &&
                    <Button title="Usuń pozycję z listy"
                            style={{marginLeft: '0.5em'}}
                            onClick={() => {
                                onRemove(idx)
                            }}>
                        <DeleteOutlined/>
                    </Button>
                }
            </li>
        </ul>
    </li>
));

const SortableContainer = sortableContainer(({children}) => {
    return <ul className="SortableList">{children}</ul>;
});

export class SortableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: props.data,
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            //console.log('Zmiana props.data');
            this.setState({
                items: this.props.data,
            });
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({items}) => ({
            items: arrayMoveImmutable(items, oldIndex, newIndex),
        }), () => this.props.onSortEnd(this.state.items));
    };

    onRemove = (key) => {
        this.setState(({items}) => ({
            items: items.filter((value, index) => index !== key)
        }), () => this.props.onRemove(this.state.items));
    }

    render() {
        const {items} = this.state;

        if (items && items.length > 0) {
            return (
                <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                    {items.map((value, index) => (
                        <SortableItem key={`item-${index}`}
                                      index={index}
                                      idx={index}
                                      value={value}
                                      onEdit={this.props.onEdit}
                                      onRemove={this.onRemove}/>
                    ))}
                </SortableContainer>
            );
        } else {
            return <Empty description={"Brak repertuaru"}/>;
        }
    }
}
