import React, {Component} from "react";
import {connect} from "react-redux";
import {startLoader, recoverPassword, recoverPasswordError} from "../../data/actionCreators";
import {message, Spin} from "antd";
import {RecoverPasswordContainer} from "./RecoverPasswordContainer";
import {recoverPasswordErrorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    recoverPassword: (data, history, onSuccess) => {
        dispatch(startLoader());
        dispatch(recoverPassword(data, (response)=> {
            if (response.data.sent) {
                message.success('Wiadomość z instrukcją ustawiania nowego hasła została wysłana na podany e-mail');
            }
            onSuccess();
            history.push('/login');
        }, (error) => {
            dispatch(recoverPasswordError(error));
            recoverPasswordErrorMessage(error);
        }));
    }
});

export const RecoverPasswordConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {

        render() {
            return <Spin spinning={this.props.loading}>
                <RecoverPasswordContainer {...this.props}/>
            </Spin>
        }
    }
);
